<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <label for="Id">ID:</label>
        <input type="text" id="Id" v-model="Id" required>
      </div>
      <div class="form-group password-group">
        <label for="password">Password:</label>
        <div class="password-container">
          <input :type="passwordFieldType" id="Password" v-model="Password" required>
          <button type="button" class="toggle-password" @click="togglePasswordVisibility">
            {{ passwordFieldType === 'password' ? 'Show' : 'Hide' }}
          </button>
        </div>
      </div>
      <button type="submit">Login</button>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <div class="links">
        <router-link to="/change-password">Change Password</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      Id: '',
      Password: '',
      errorMessage: '',
      passwordFieldType: 'password'
    }
  },
  methods: {
    ...mapActions(['authenticateUser']),
    handleLogin () {
      axios.post('https://internal.cnexco.com:3000/login', {
        Id: this.Id,
        Password: this.Password
      })
        .then(response => {
          if (response.data.accessToken) {
            localStorage.setItem('authToken', response.data.accessToken)
            localStorage.setItem('employeeId', this.Id)
            localStorage.setItem('systemAccessLevel', response.data.systemAccessLevel) // Store SystemAccessLevel
            localStorage.setItem('inTime', response.data.inTime) // Store InTime
            localStorage.setItem('outTime', response.data.outTime) // Store OutTime
            this.authenticateUser({
              token: response.data.accessToken,
              level: response.data.systemAccessLevel,
              inTime: response.data.inTime, // Pass InTime
              outTime: response.data.outTime // Pass OutTime
            }) // Vuexストアのアクションを呼び出す
            this.$router.push('/dashboard') // リダイレクト先を変更
          } else {
            this.errorMessage = 'Login failed. Please check your credentials.'
          }
        })
        .catch(error => {
          this.errorMessage = error.response?.data?.message || 'Login failed. Please check your credentials.'
          console.error('Login error:', error)
        })
    },
    togglePasswordVisibility () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.password-group {
  position: relative;
}

.password-container {
  display: flex;
  align-items: center;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

input[type="text"], input[type="password"] {
  width: calc(100% - 50px);
  padding: 10px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.toggle-password {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 10px;
}

.toggle-password:hover {
  color: #0056b3;
}

.error {
  color: red;
  text-align: center;
}

.links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.links a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.links a:hover {
  color: #0056b3;
}
</style>

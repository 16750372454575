<template>
    <div class="content">
      <!-- Employee Information -->
      <div class="employee-info">
        <h2>Employee ID: {{ selectedEmployee.id }} - Name: {{ selectedEmployee.name }}</h2>
      </div>

      <!-- Fiscal Year Selection -->
      <div class="fiscal-year-selection">
        <label for="fiscalYear">Select Fiscal Year:</label>
        <select v-model="selectedFiscalYear" @change="fetchAllData">
          <option v-for="year in fiscalYears" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>

      <!-- Sick Leave Information -->
      <div class="sick-leave-table">
        <table>
          <thead>
            <tr>
              <th>{{ selectedFiscalYear }} Sick Leave</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ formattedTotalSickLeave }}</td>
            </tr>
            <tr>
              <td>Used</td>
              <td>{{ formattedUsedSickLeave }}</td>
            </tr>
            <tr>
              <td>Remain</td>
              <td>{{ formattedRemainingSickLeave }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Table with Dates -->
      <div class="date-table full-width">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Sick Leave</th>
              <th>Self</th>
              <th>Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="date in dates" :key="date.ID" :class="{ 'confirmed': date.SelfConf, 'supervisor-confirmed': date.SupervisorConf }">
              <td>{{ date.Date.split('T')[0] }}</td>
              <td>
                <select v-model="date.SickLeave" @change="handleLeaveChange(date, 'SickLeave')" :disabled="date.future || date.SelfConf || date.SupervisorConf">
                  <option v-for="option in timeOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </td>
              <td>
                <button @click="toggleConfirmation(date)" :disabled="date.future || date.SupervisorConf">{{ date.SelfConf ? 'Undo' : 'Confirm' }}</button>
              </td>
              <td>
                <span v-if="date.SupervisorConf" class="supervisor-confirmed-text">Confirmed</span>
              </td>
            </tr>
            <!-- Row for adding new record -->
            <tr>
              <td>
                <input type="date" v-model="newRecord.Date" />
              </td>
              <td>
                <select v-model="newRecord.SickLeave">
                  <option v-for="option in timeOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </td>
              <td>
                <button @click="addNewRecord">{{ newRecord.SelfConf ? 'Undo' : 'Confirm' }}</button>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import { mapState } from 'vuex'

export default {
  data () {
    const now = moment.tz('America/Chicago')
    const currentYear = now.year()
    return {
      currentYear: currentYear,
      currentMonth: now.month() + 1,
      currentDate: now.date(),
      selectedEmployee: { id: '', name: '' },
      dates: [],
      newRecord: {
        Date: '',
        SickLeave: '00:00',
        SelfConf: false
      },
      timeOptions: [],
      totalSickLeaveHours: 0,
      usedSickLeaveHours: 0,
      remainingSickLeaveHours: 0,
      previousSickLeaveValues: {},
      holidays: [],
      fiscalYears: this.generateFiscalYears(currentYear),
      selectedFiscalYear: `FY${currentYear}`
    }
  },
  computed: {
    ...mapState(['inTime', 'outTime']),
    formattedUsedSickLeave () {
      return this.hoursToDaysAndTimeString(this.usedSickLeaveHours)
    },
    formattedRemainingSickLeave () {
      return this.hoursToDaysAndTimeString(this.remainingSickLeaveHours)
    },
    formattedTotalSickLeave () {
      return this.hoursToDaysAndTimeString(this.totalSickLeaveHours)
    }
  },
  watch: {
    selectedFiscalYear (newFiscalYear) {
      this.fetchAllData()
    }
  },
  methods: {
    checkAuth () {
      const authToken = localStorage.getItem('authToken')
      const employeeId = localStorage.getItem('employeeId')
      if (!authToken || !employeeId) {
        this.$router.push({ name: 'login' })
      } else {
        this.fetchEmployeeData(employeeId)
      }
    },
    fetchEmployeeData (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/employee/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Employee data fetched:', response.data)
          this.selectedEmployee.id = response.data.ID
          this.selectedEmployee.name = response.data.Username
          this.fetchAllData()
        })
        .catch(error => {
          console.error('Error fetching employee data:', error)
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('employeeId')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fetchAllData () {
      this.fetchSickLeaveData()
      this.fetchHolidays(this.currentYear)
      this.fetchSickLeaveApprovalData(this.selectedEmployee.id)
    },
    fetchSickLeaveData () {
      axios.get(`https://internal.cnexco.com:3000/sickleave/${this.selectedEmployee.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Sick Leave data fetched:', response.data)
          const fiscalYearNumber = parseInt(this.selectedFiscalYear.replace('FY', ''), 10)
          const sickLeaveData = response.data.find(data => data.Year === fiscalYearNumber)
          if (sickLeaveData) {
            this.totalSickLeaveHours = sickLeaveData.SickLeave * 7.5
            this.calculateUsedSickLeave(response.data)
            this.remainingSickLeaveHours = this.totalSickLeaveHours - this.usedSickLeaveHours
          } else {
            this.totalSickLeaveHours = 0
            this.usedSickLeaveHours = 0
            this.remainingSickLeaveHours = 0
          }
        })
        .catch(error => {
          console.error('Error fetching Sick Leave data:', error)
        })
    },
    fetchSickLeaveApprovalData (employeeId) {
      const fiscalYearNumber = parseInt(this.selectedFiscalYear.replace('FY', ''), 10)
      axios.get(`https://internal.cnexco.com:3000/sickleaveapprove/${employeeId}/year/${fiscalYearNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Sick Leave approval data fetched:', response.data)
          this.dates = response.data.map(date => {
            return {
              ...date,
              Date: date.Date.split('T')[0],
              SickLeave: date.SickLeave.slice(0, 5)
            }
          })

          // ここでDateの昇順にソートします
          this.dates.sort((a, b) => new Date(a.Date) - new Date(b.Date))

          this.calculateUsedSickLeave() // Sick Leave approval dataからUsed Sick Leaveを計算
        })
        .catch(error => {
          console.error('Error fetching Sick Leave approval data:', error)
        })
    },
    fetchHolidays (year) {
      axios.get(`https://internal.cnexco.com:3000/holidays/${year}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.holidays = response.data
        })
        .catch(error => {
          console.error('Error fetching holidays:', error)
        })
    },
    calculateUsedSickLeave () {
      this.usedSickLeaveHours = this.dates.reduce((total, record) => {
        return total + this.timeStringToHours(record.SickLeave)
      }, 0)
      this.remainingSickLeaveHours = this.totalSickLeaveHours - this.usedSickLeaveHours
    },
    handleLeaveChange (date, field) {
      const previousValue = this.previousSickLeaveValues[date.Date] || 0
      const newValue = this.timeStringToHours(date[field])

      const currentAbsenceWithoutLeave = date.start ? this.calculateAbsenceWithoutLeave(date) : '7:30'
      const totalLeaveAndOther = this.timeStringToHours(date.SickLeave) + this.timeStringToHours(date.other)

      if (totalLeaveAndOther > this.timeStringToHours(currentAbsenceWithoutLeave)) {
        alert(`Total of Sick Leave and Other cannot exceed Absence. Maximum: ${currentAbsenceWithoutLeave}`)
        date.SickLeave = '00:00'
        date.other = '00:00'
        date.absence = currentAbsenceWithoutLeave
      } else {
        date.absence = this.hoursToTimeString(this.timeStringToHours(currentAbsenceWithoutLeave) - totalLeaveAndOther)
      }

      const difference = newValue - previousValue
      this.usedSickLeaveHours += difference
      this.remainingSickLeaveHours = this.totalSickLeaveHours - this.usedSickLeaveHours

      this.previousSickLeaveValues[date.Date] = newValue

      this.recalculateUsedSickLeave()
    },
    recalculateUsedSickLeave () {
      this.usedSickLeaveHours = this.dates.reduce((total, date) => {
        return total + this.timeStringToHours(date.SickLeave)
      }, 0)
      this.remainingSickLeaveHours = this.totalSickLeaveHours - this.usedSickLeaveHours
    },
    toggleConfirmation (date) {
      if (!date.SelfConf) {
        this.addRecord(date)
      } else {
        this.deleteRecord(date)
      }
    },
    addRecord (date) {
      if (!date.Date || date.SickLeave === '00:00') {
        alert('Date and Sick Leave must be provided and Sick Leave cannot be 00:00.')
        return
      }

      if (this.exceedsDailyLimit(date.Date, date.SickLeave)) {
        alert('Total Sick Leave for the date exceeds 7:30 hours. Please adjust the Sick Leave time.')
        return
      }

      const recordData = {
        EmployeeID: this.selectedEmployee.id,
        Date: date.Date,
        SickLeave: date.SickLeave || '00:00:00',
        SelfConf: 1
      }
      axios.post('https://internal.cnexco.com:3000/sickleaveapprove', recordData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Record added:', response.data)
          this.fetchSickLeaveApprovalData(this.selectedEmployee.id)
        })
        .catch(error => {
          console.error('Error adding record:', error)
        })
    },
    deleteRecord (date) {
      const recordData = {
        EmployeeID: this.selectedEmployee.id,
        Date: date.Date
      }
      axios.delete('https://internal.cnexco.com:3000/sickleaveapprove', {
        data: recordData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Record deleted:', response.data)
          this.fetchSickLeaveApprovalData(this.selectedEmployee.id)
        })
        .catch(error => {
          console.error('Error deleting record:', error)
        })
    },
    addNewRecord () {
      if (!this.newRecord.Date || this.newRecord.SickLeave === '00:00') {
        alert('Date and Sick Leave must be provided and Sick Leave cannot be 00:00.')
        return
      }

      if (this.exceedsDailyLimit(this.newRecord.Date, this.newRecord.SickLeave)) {
        alert('Total Sick Leave for the date exceeds 7:30 hours. Please adjust the Sick Leave time.')
        return
      }

      const newRecordData = {
        EmployeeID: this.selectedEmployee.id,
        Date: this.newRecord.Date,
        SickLeave: this.newRecord.SickLeave || '00:00:00',
        SelfConf: 1
      }
      axios.post('https://internal.cnexco.com:3000/sickleaveapprove', newRecordData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('New record added:', response.data)
          this.fetchSickLeaveApprovalData(this.selectedEmployee.id)
          this.newRecord = { Date: '', SickLeave: '00:00', SelfConf: false }
        })
        .catch(error => {
          console.error('Error adding new record:', error)
        })
    },
    exceedsDailyLimit (date, newSickLeave) {
      let totalSickLeaveForDate = this.dates.reduce((total, record) => {
        if (record.Date === date) {
          return total + this.timeStringToHours(record.SickLeave)
        }
        return total
      }, 0)
      totalSickLeaveForDate += this.timeStringToHours(newSickLeave)
      return totalSickLeaveForDate > 7.5
    },
    generateTimeOptions (maxTime) {
      const maxHours = this.timeStringToHours(maxTime)
      const options = []
      for (let i = 0; i <= maxHours; i += 0.5) {
        options.push(this.hoursToTimeString(i))
      }
      return options
    },
    timeStringToHours (timeStr) {
      if (typeof timeStr !== 'string') return 0
      const parts = timeStr.split(':')
      return parseFloat(parts[0]) + parseFloat(parts[1]) / 60
    },
    hoursToTimeString (hours) {
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')}`
    },
    hoursToDaysAndTimeString (hours) {
      const days = (hours / 7.5).toFixed(2)
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${days} Days (${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')})`
    },
    generateFiscalYears (currentYear) {
      const startYear = 2023
      const fiscalYears = []
      for (let i = startYear; i <= currentYear; i++) {
        fiscalYears.push(`FY${i}`)
      }
      return fiscalYears
    }
  },
  created () {
    this.timeOptions = this.generateTimeOptions('7:30')
    this.checkAuth()
  }
}
</script>

  <style scoped>
  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 20px;
  }

  .pto-and-date-switcher {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .fiscal-year-selection {
    margin-bottom: 20px;
    font-size: 1.2em;
  }

  .fiscal-year-selection select {
    font-size: 1.2em;
  }

  .employee-info, .date-table {
    margin-bottom: 20px;
  }

  .sick-leave-table {
    margin-top: 10px;
  }

  .date-table {
    width: 100%;
    overflow-x: auto;
  }

  .date-table table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    text-align: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  th span {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8vw;
  }

  th span.adjusted {
    font-size: 0.8vw;
  }

  .confirmed {
    background-color: #e0e0e0;
  }

  .supervisor-confirmed-text {
    color: green;
    font-weight: bold;
  }

  .full-width {
    width: 100%;
  }
  </style>

<template>
    <div class="container">
      <h1>Holiday Master Management</h1>
      <table>
        <thead>
          <tr>
            <th>Holiday Name</th>
            <th>Fixed Date</th>
            <th>Holiday Logic</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="holiday in holidays" :key="holiday.id">
            <td>{{ holiday.holiday_name }}</td>
            <td>{{ holiday.fixed_date }}</td>
            <td>{{ holiday.holiday_logic }}</td>
          </tr>
        </tbody>
      </table>

      <h2>Add New Holiday</h2>
      <div class="form-inline">
        <input placeholder="Holiday Name" v-model="newHoliday.holiday_name">
        <input type="date" placeholder="Fixed Date" v-model="newHoliday.fixed_date">
        <button @click="addHoliday">Add Holiday</button>
      </div>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      holidays: [],
      newHoliday: {
        holiday_name: '',
        fixed_date: ''
      }
    }
  },
  created () {
    this.fetchHolidays()
  },
  methods: {
    fetchHolidays () {
      axios.get('https://internal.cnexco.com:3000/manage-holidays')
        .then(response => {
          this.holidays = response.data
        })
        .catch(error => {
          console.error('Error fetching holidays:', error)
        })
    },
    addHoliday () {
      if (!this.newHoliday.holiday_name || !this.newHoliday.fixed_date) {
        alert('Please fill in all fields')
        return
      }

      axios.post('https://internal.cnexco.com:3000/manage-holidays', this.newHoliday)
        .then(() => {
          alert('Holiday added successfully')
          this.fetchHolidays() // Refresh the holidays list
          this.newHoliday = { holiday_name: '', fixed_date: '' }
        })
        .catch(error => {
          console.error('Error adding holiday:', error)
        })
    }
  }
}
</script>

  <style scoped>
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }

  h1, h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  input[type="text"], input[type="date"] {
    width: calc(100% - 16px);
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  button {
    padding: 10px 20px;
    margin: 10px 5px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: #4CAF50;
    color: white;
    border-radius: 4px;
  }

  button:hover {
    background-color: #45a049;
  }

  .form-inline {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .form-inline input {
    flex: 1;
  }

  .form-inline button {
    flex: none;
  }
  </style>

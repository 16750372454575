<template>
  <div class="content">
    <!-- 社員情報 -->
    <div class="employee-info">
      <h2>Employee ID: {{ selectedEmployee.id }} - Name: {{ selectedEmployee.name }}</h2>
    </div>

    <!-- 従業員のリスト -->
    <div class="employee-list">
      <h3>List of Employees</h3>
      <ul>
        <li v-for="employee in employees" :key="employee.Id" class="employee-item">
          <input type="radio" :value="employee.Id" v-model="selectedEmployeeId" @change="fetchEmployeeData">
          <span>{{ employee.Username }}</span>
        </li>
      </ul>
    </div>

    <!-- テーブル -->
    <div class="tables">
      <!-- PTOとSickLeaveのペア -->
      <div class="table-pair">
        <!-- PTOテーブル -->
        <div class="table-container">
          <h3>PTO</h3>
          <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Value</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in ptoData" :key="record.Year">
                <td>{{ record.Year }}</td>
                <td>{{ record.PTO }}</td>
                <td><button class="delete-button" @click="confirmDelete('pto', record.Year)">Delete</button></td>
              </tr>
            </tbody>
          </table>
          <div class="input-container">
            <input v-model="newPTO.year" placeholder="Year">
            <input v-model="newPTO.value" placeholder="Value">
            <button @click="addRecord('pto')">Add</button>
          </div>
        </div>

        <!-- SickLeaveテーブル -->
        <div class="table-container">
          <h3>Sick Leave</h3>
          <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Value</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in sickLeaveData" :key="record.Year">
                <td>{{ record.Year }}</td>
                <td>{{ record.SickLeave }}</td>
                <td><button class="delete-button" @click="confirmDelete('sickleave', record.Year)">Delete</button></td>
              </tr>
            </tbody>
          </table>
          <div class="input-container">
            <input v-model="newSickLeave.year" placeholder="Year">
            <input v-model="newSickLeave.value" placeholder="Value">
            <button @click="addRecord('sickleave')">Add</button>
          </div>
        </div>
      </div>

      <!-- SalaryとPerformanceのペア -->
      <div class="table-pair">
        <!-- Salaryテーブル -->
        <div class="table-container">
          <h3>Salary</h3>
          <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Value</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in salaryData" :key="record.Year">
                <td>{{ record.Year }}</td>
                <td>{{ record.Salary }}</td>
                <td><button class="delete-button" @click="confirmDelete('salary', record.Year)">Delete</button></td>
              </tr>
            </tbody>
          </table>
          <div class="input-container">
            <input v-model="newSalary.year" placeholder="Year">
            <input v-model="newSalary.value" placeholder="Value">
            <button @click="addRecord('salary')">Add</button>
          </div>
        </div>

        <!-- Performanceテーブル -->
        <div class="table-container">
          <h3>Performance</h3>
          <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Value</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in performanceData" :key="record.Year">
                <td>{{ record.Year }}</td>
                <td>{{ record.Performance }}</td>
                <td><button class="delete-button" @click="confirmDelete('performance', record.Year)">Delete</button></td>
              </tr>
            </tbody>
          </table>
          <div class="input-container">
            <input v-model="newPerformance.year" placeholder="Year">
            <input v-model="newPerformance.value" placeholder="Value">
            <button @click="addRecord('performance')">Add</button>
          </div>
        </div>
      </div>
    </div>

    <!-- エラーメッセージ -->
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      loginEmployee: { id: '', name: '' },
      selectedEmployee: { id: '', name: '' },
      employees: [],
      selectedEmployeeId: null,
      ptoData: [],
      salaryData: [],
      performanceData: [],
      sickLeaveData: [], // SickLeave データを追加
      newPTO: { year: '', value: '' },
      newSalary: { year: '', value: '' },
      newPerformance: { year: '', value: '' },
      newSickLeave: { year: '', value: '' }, // SickLeave 入力フィールドを追加
      errorMessage: ''
    }
  },
  methods: {
    checkAuth () {
      const authToken = localStorage.getItem('authToken')
      const employeeId = localStorage.getItem('employeeId')
      if (!authToken || !employeeId) {
        this.$router.push({ name: 'login' })
      } else {
        this.fetchEmployeeData(employeeId)
        this.fetchAllEmployees()
      }
    },
    fetchEmployeeData (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/employee/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Employee data fetched:', response.data)
          this.loginEmployee.id = response.data.ID
          this.loginEmployee.name = response.data.Username
          this.selectedEmployee.id = response.data.ID
          this.selectedEmployee.name = response.data.Username
          this.selectedEmployeeId = response.data.ID
          this.fetchTableData('pto')
          this.fetchTableData('salary')
          this.fetchTableData('performance')
          this.fetchTableData('sickleave') // SickLeave データを取得
        })
        .catch(error => {
          console.error('Error fetching employee data:', error)
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('employeeId')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fetchAllEmployees () {
      axios.get('https://internal.cnexco.com:3000/employees', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('All employees data fetched:', response.data)
          this.employees = response.data
        })
        .catch(error => {
          console.error('Error fetching all employees data:', error)
        })
    },
    confirmDelete (table, year) {
      if (confirm('Are you sure to delete this record?')) {
        this.deleteRecord(table, year)
      }
    },
    deleteRecord (table, year) {
      axios.delete(`https://internal.cnexco.com:3000/${table}/${this.selectedEmployeeId}/${year}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log(`${table} record deleted:`, response.data)
          this.fetchTableData(table)
        })
        .catch(error => {
          console.error(`Error deleting ${table} record:`, error)
        })
    },
    fetchTableData (table) {
      axios.get(`https://internal.cnexco.com:3000/${table}/${this.selectedEmployeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log(`${table} data fetched:`, response.data)
          if (table === 'pto') {
            this.ptoData = response.data
          } else if (table === 'salary') {
            this.salaryData = response.data.map(record => ({
              ...record,
              Salary: `$${parseInt(record.Salary).toLocaleString()} USD`
            }))
          } else if (table === 'performance') {
            this.performanceData = response.data
          } else if (table === 'sickleave') {
            this.sickLeaveData = response.data
          }
        })
        .catch(error => {
          console.error(`Error fetching ${table} data:`, error)
        })
    },
    addRecord (table) {
      let newRecord = {}
      if (table === 'pto') {
        if (isNaN(this.newPTO.value) || this.newPTO.value.trim() === '') {
          this.errorMessage = 'Input data does not match the requirement!'
          this.clearInputs(table)
          return
        }
        newRecord = { year: this.newPTO.year, value: this.newPTO.value }
      } else if (table === 'salary') {
        if (isNaN(this.newSalary.value) || this.newSalary.value.trim() === '') {
          this.errorMessage = 'Input data does not match the requirement!'
          this.clearInputs(table)
          return
        }
        newRecord = { year: this.newSalary.year, value: this.newSalary.value }
      } else if (table === 'performance') {
        if (this.newPerformance.value.length > 25 || this.newPerformance.value.trim() === '') {
          this.errorMessage = 'Input data does not match the requirement!'
          this.clearInputs(table)
          return
        }
        newRecord = { year: this.newPerformance.year, value: this.newPerformance.value }
      } else if (table === 'sickleave') {
        if (isNaN(this.newSickLeave.value) || this.newSickLeave.value.trim() === '') {
          this.errorMessage = 'Input data does not match the requirement!'
          this.clearInputs(table)
          return
        }
        newRecord = { year: this.newSickLeave.year, value: this.newSickLeave.value }
      }

      axios.post(`https://internal.cnexco.com:3000/${table}`, {
        EmployeeID: this.selectedEmployeeId,
        Year: newRecord.year,
        Value: newRecord.value
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log(`${table} record added:`, response.data)
          this.fetchTableData(table)
          this.clearInputs(table)
        })
        .catch(error => {
          if (error.response && error.response.data === 'The record already exists!') {
            this.errorMessage = 'The record already exists!'
          } else {
            console.error(`Error adding ${table} record:`, error)
          }
          this.clearInputs(table)
        })
    },
    clearInputs (table) {
      if (table === 'pto') {
        this.newPTO.year = ''
        this.newPTO.value = ''
      } else if (table === 'salary') {
        this.newSalary.year = ''
        this.newSalary.value = ''
      } else if (table === 'performance') {
        this.newPerformance.year = ''
        this.newPerformance.value = ''
      } else if (table === 'sickleave') {
        this.newSickLeave.year = ''
        this.newSickLeave.value = ''
      }
      this.errorMessage = ''
    }
  },
  watch: {
    selectedEmployeeId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchTableData('pto')
        this.fetchTableData('salary')
        this.fetchTableData('performance')
        this.fetchTableData('sickleave') // SickLeave データを取得
      }
    }
  },
  created () {
    this.checkAuth()
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px;
}

.employee-info, .employee-list, .tables {
  margin-bottom: 20px;
}

.employee-list ul {
  list-style-type: none;
  padding: 0;
}

.employee-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

/* テーブルをペアで並べるためのスタイル */
.table-pair {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.table-container {
  flex: 1;
  margin: 0 10px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.input-container {
  margin-top: 10px;
}

input {
  margin-right: 5px;
  padding: 5px;
}

button {
  background-color: green;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

button:hover {
  background-color: darkgreen;
}

.delete-button {
  background-color: red;
  color: white;
}

.delete-button:hover {
  background-color: darkred;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}
</style>

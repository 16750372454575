<template>
    <div class="register-container">
      <h2>Register New User</h2>
      <form @submit.prevent="registerUser">
        <div class="form-group">
          <label for="id">ID:</label>
          <input type="number" v-model.number="form.id" @input="validateId" required />
        </div>
        <div class="form-group">
          <label for="username">Username:</label>
          <input type="text" v-model="form.username" required />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input :type="showPassword ? 'text' : 'password'" v-model="form.password" required />
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password:</label>
          <input :type="showPassword ? 'text' : 'password'" v-model="form.confirmPassword" required />
        </div>
        <div class="form-group show-password">
          <input type="checkbox" v-model="showPassword" id="showPassword" />
          <label for="showPassword">Show Password</label>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" v-model="form.email" required />
        </div>
        <div class="form-group">
          <label for="joinDate">Join Date:</label>
          <input type="date" v-model="form.joinDate" required />
        </div>
        <div class="form-group">
          <label for="department">Department:</label>
          <input type="text" v-model="form.department" required />
        </div>
        <div class="form-group">
          <label for="intime">In Time:</label>
          <input type="time" v-model="form.intime" required />
        </div>
        <div class="form-group">
          <label for="outtime">Out Time:</label>
          <input type="time" v-model="form.outtime" required />
        </div>
        <div class="form-group">
          <label for="systemAccessLevel">System Access Level:</label>
          <select v-model="form.systemAccessLevel" required>
            <option value="Staff">Staff</option>
            <option value="Management">Management</option>
            <option value="Administrator">Administrator</option>
          </select>
        </div>
        <div class="form-group">
          <label for="supervisorName">Supervisor Name:</label>
          <input type="text" v-model="form.supervisorName" :required="form.hasSupervisor" :disabled="!form.hasSupervisor" />
          <input type="checkbox" v-model="form.hasSupervisor" id="hasSupervisor" />
        </div>
        <button type="submit">Register</button>
      </form>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      form: {
        id: null,
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
        joinDate: '',
        department: '',
        systemAccessLevel: 'Staff',
        intime: '',
        outtime: '',
        hasSupervisor: false,
        supervisorName: ''
      },
      showPassword: false
    }
  },
  methods: {
    async registerUser () {
      if (this.form.password !== this.form.confirmPassword) {
        this.showMessage('Passwords do not match!')
        return
      }

      if (!this.validateTimeDifference()) {
        this.showMessage('The difference between the Out Time and the In Time must be 8:30: regular work hours for 7:30 and 1 hour break')
        this.form.intime = ''
        this.form.outtime = ''
        return
      }

      try {
        if (!this.form.hasSupervisor) {
          this.form.supervisorName = null
        }

        await axios.post('https://internal.cnexco.com:3000/register', this.form)
        this.showMessage('User registered successfully!')
      } catch (error) {
        if (error.response && error.response.data) {
          this.showMessage('Error registering user: ' + error.response.data)
        } else {
          this.showMessage('Error registering user: ' + error.message)
        }
      }
    },
    validateId (event) {
      const value = event.target.value
      if (value < 1) {
        this.form.id = ''
      }
    },
    validateTimeDifference () {
      const intime = this.form.intime
      const outtime = this.form.outtime

      if (!intime || !outtime) {
        return false
      }

      const [inHours, inMinutes] = intime.split(':').map(Number)
      const [outHours, outMinutes] = outtime.split(':').map(Number)

      const inTimeInMinutes = inHours * 60 + inMinutes
      const outTimeInMinutes = outHours * 60 + outMinutes

      const differenceInMinutes = outTimeInMinutes - inTimeInMinutes

      return differenceInMinutes === 510 // 8 hours 30 minutes = 510 minutes
    },
    showMessage (message) {
      alert(message)
    }
  }
}
</script>

  <style scoped>
  .register-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    text-align: center;
  }

  form {
    width: 100%;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .form-group label {
    width: 150px;
    margin-right: 10px;
    text-align: right;
  }

  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group input[type="password"],
  .form-group input[type="email"],
  .form-group input[type="date"],
  .form-group input[type="time"],
  .form-group select {
    flex: 1;
    padding: 8px;
  }

  .form-group.show-password {
    justify-content: flex-start;
  }

  .form-group.show-password input[type="checkbox"] {
    margin-left: 160px;
  }

  .form-group.show-password label {
    margin-left: 5px;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
  </style>

import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios' // axiosをインポート
import TimeSheet from '@/views/TimeSheet.vue'
import UserLogin from '@/views/UserLogin.vue'
import AddUser from '@/views/AddUser.vue'
import TimeCheck from '@/views/TimeCheck.vue'
import HRMSetting from '@/views/HRMSetting.vue'
import TimeAdmin from '@/views/TimeAdmin.vue'
import TimeDashboard from '@/views/TimeDashboard.vue'
import UserUpdate from '@/views/UserUpdate.vue'
import HolidayUpdate from '@/views/HolidayUpdate.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import RequestPTO from '@/views/RequestPTO.vue'
import CheckPTO from '@/views/CheckPTO.vue'
import OverTimeOrder from '@/views/OverTimeOrder.vue'
import RequestSickLeave from '@/views/RequestSickLeave.vue'
import CheckSickLeave from '@/views/CheckSickLeave.vue'

// アクセスレベルの定義
const accessLevels = {
  Administrator: 3,
  Management: 2,
  Staff: 1
}

const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/timesheet',
    name: 'Timesheet',
    component: TimeSheet,
    meta: { requiresAuth: true, accessLevel: accessLevels.Staff } // 最低限Staffアクセスが必要
  },
  {
    path: '/timecheck',
    name: 'TimeCheck',
    component: TimeCheck,
    meta: { requiresAuth: true, accessLevel: accessLevels.Management } // 最低限Managementアクセスが必要
  },
  {
    path: '/hrmsetting',
    name: 'HRMSetting',
    component: HRMSetting,
    meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
  },
  {
    path: '/timeadmin',
    name: 'TimeAdmin',
    component: TimeAdmin,
    meta: { requiresAuth: true, accessLevel: accessLevels.Management } // 最低限Managementアクセスが必要
  },
  {
    path: '/dashboard',
    component: TimeDashboard,
    children: [
      {
        path: 'attendance/timesheet',
        component: TimeSheet,
        meta: { requiresAuth: true, accessLevel: accessLevels.Staff } // 最低限Staffアクセスが必要
      },
      {
        path: 'attendance/timecheck',
        component: TimeCheck,
        meta: { requiresAuth: true, accessLevel: accessLevels.Management } // 最低限Managementアクセスが必要
      },
      {
        path: 'attendance/administration',
        component: TimeAdmin,
        meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
      },
      {
        path: 'attendance/request-pto',
        component: RequestPTO,
        meta: { requiresAuth: true, accessLevel: accessLevels.Staff } // 最低限Staffアクセスが必要
      },
      {
        path: 'attendance/request-sickleave',
        component: RequestSickLeave,
        meta: { requiresAuth: true, accessLevel: accessLevels.Staff } // 最低限Staffアクセスが必要
      },
      {
        path: 'attendance/check-pto',
        component: CheckPTO,
        meta: { requiresAuth: true, accessLevel: accessLevels.Management } // 最低限Managementアクセスが必要
      },
      {
        path: 'attendance/check-sickleave',
        component: CheckSickLeave,
        meta: { requiresAuth: true, accessLevel: accessLevels.Management } // 最低限Managementアクセスが必要
      },
      {
        path: 'attendance/overtime-order',
        component: OverTimeOrder,
        meta: { requiresAuth: true, accessLevel: accessLevels.Management } // 最低限Managementアクセスが必要
      },
      {
        path: 'hrm/add-user',
        component: AddUser,
        meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
      },
      {
        path: 'hrm/update-user',
        component: UserUpdate,
        meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
      },
      {
        path: 'hrm/hrmsetting',
        component: HRMSetting,
        meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
      },
      {
        path: 'system/update-holiday',
        component: HolidayUpdate,
        meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
      }
    ]
  },
  {
    path: '/add-user',
    name: 'AddUser',
    component: AddUser,
    meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiresAuth: false }
  },
  {
    path: '/update-user',
    name: 'UserUpdate',
    component: UserUpdate,
    meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
  },
  {
    path: '/update-holiday',
    name: 'HolidayUpdate',
    component: HolidayUpdate,
    meta: { requiresAuth: true, accessLevel: accessLevels.Administrator } // 最低限Administratorアクセスが必要
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// beforeEachナビゲーションガードの追加
router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem('authToken')
  const userAccessLevel = localStorage.getItem('systemAccessLevel')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authToken) {
      next({ name: 'UserLogin' })
    } else {
      // 認証トークンを検証するAPIリクエストを追加
      axios.post('https://internal.cnexco.com:3000/verify-token', { token: authToken })
        .then(response => {
          if (response.data.valid && accessLevels[userAccessLevel] >= to.meta.accessLevel) {
            next()
          } else {
            next({ name: 'Timesheet' }) // アクセス権がない場合、デフォルトページにリダイレクト
          }
        })
        .catch(() => {
          next({ name: 'UserLogin' }) // トークンが無効な場合、ログインページにリダイレクト
        })
    }
  } else {
    next()
  }
})

export default router

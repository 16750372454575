<template>
  <div class="content">
    <!-- 社員情報 -->
    <div class="employee-info">
      <h2>Employee ID: {{ selectedEmployee.id }} - Name: {{ selectedEmployee.name }}</h2>
    </div>

    <!-- 従業員の選択 -->
    <div class="employees-list">
      <h3>Attendance Check Progress</h3>
      <ul>
        <li v-for="employee in employees" :key="employee.Id" class="subordinate-item">
          <input type="radio" :value="employee.Id" v-model="selectedSubordinate" @change="fetchAttendanceData">
          <span>{{ employee.Username }}</span>
          <div class="progress-container">
            <div class="progress-bar-container">
              <div class="progress-bar" :style="progressBarStyle(employee.Id)"></div>
            </div>
            <div :style="progressTextStyle(employee.Id)">{{ progressPercentage(employee.Id) }}%</div>
            <button @click="downloadCSV(employee.Id, employee.Username)" class="csv-button">CSV</button>
          </div>
        </li>
      </ul>
    </div>

    <!-- PTO情報 -->
    <div class="pto-section">
      <div class="pto-table">
        <table>
          <thead>
            <tr>
              <th colspan="2">{{ fiscalYear }} PTO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ formattedTotalPTO }}</td>
            </tr>
            <tr>
              <td>Used</td>
              <td>{{ formattedUsedPTO }}</td>
            </tr>
            <tr>
              <td :class="{'remain-pto-negative': remainingPTOHours <= 0}">Remain</td>
              <td :class="{'remain-pto-negative': remainingPTOHours <= 0}">{{ formattedRemainingPTO }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Sick Leave情報 -->
      <div class="pto-table">
        <table>
          <thead>
            <tr>
              <th colspan="2">{{ fiscalYear }} Sick Leave</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ formattedTotalSickLeave }}</td>
            </tr>
            <tr>
              <td>Used</td>
              <td>{{ formattedUsedSickLeave }}</td>
            </tr>
            <tr>
              <td :class="{'remain-pto-negative': remainingSickLeaveHours <= 0}">Remain</td>
              <td :class="{'remain-pto-negative': remainingSickLeaveHours <= 0}">{{ formattedRemainingSickLeave }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Date and Month Switcher -->
      <div class="date-switcher">
        <button @click="moveBackward">
          <b>&lt; Previous &boxv;&boxv;</b>
        </button>
        <span>{{ displayDate }}</span>
        <button @click="moveForward">
          <b>&boxv;&boxv; Next &gt;</b>
        </button>
      </div>
    </div>

    <!-- 日にちを含む表 -->
    <div class="date-table full-width">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Start</th>
            <th>Mid End</th>
            <th>Mid Start</th>
            <th>End</th>
            <th>Break</th>
            <th>PTO</th>
            <th>Sick Lv</th>
            <th>Hours</th>
            <th>OT</th>
            <th>OT Order</th> <!-- OT Orderカラム -->
            <th>Abs</th>
            <th>Self</th>
            <th>Sup</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="date in dates" :key="date.day" :class="{ 'confirmed': date.supervisorConfirmed }">
            <td>{{ date.day }}</td>
            <td>
              <input type="time" v-model="date.start">
            </td>
            <td>
              <input type="time" v-model="date.partialEnd">
            </td>
            <td>
              <input type="time" v-model="date.partialStart">
            </td>
            <td>
              <input type="time" v-model="date.end">
            </td>
            <td>{{ date.break }}</td>
            <td>{{ date.paidLeave }}</td>
            <td>{{ date.other }}</td>
            <td>{{ date.hours }}</td>
            <td>{{ date.overtime }}</td>
            <td :style="{ color: date.otOrder === '0:00' ? 'black' : 'red' }">{{ date.otOrder }}</td> <!-- OT Orderの値を表示 -->
            <td>{{ date.absence }}</td>
            <td>
              <button @click="toggleConfirmation(date)">{{ date.confirmed ? 'Undo' : 'Confirm' }}</button>
            </td>
            <td>
              <button @click="toggleSupervisorConfirmation(date)"
                      :style="date.supervisorConfirmed ? { backgroundColor: 'green', color: 'white' } : { backgroundColor: 'red', color: 'white' }">
                {{ date.supervisorConfirmed ? 'Undo' : 'Confirm' }}
              </button>
            </td>
          </tr>
          <tr>
            <td><strong>Total</strong></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td><strong>{{ totalPaidLeave }}</strong></td>
            <td><strong>{{ totalOther }}</strong></td>
            <td><strong>{{ totalWorkHours }}</strong></td>
            <td><strong>{{ totalOvertime }}</strong></td>
            <td></td>
            <td><strong>{{ totalAbsence }}</strong></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      currentDate: new Date().getDate(),
      selectedEmployee: { id: '', name: '' },
      employees: [],
      selectedSubordinate: null,
      dates: [],
      attendanceData: {},
      totalPTOHours: 0,
      usedPTOHours: 0,
      remainingPTOHours: 0,
      totalSickLeaveHours: 0,
      usedSickLeaveHours: 0,
      remainingSickLeaveHours: 0,
      holidays: []
    }
  },
  computed: {
    displayDate () {
      const monthHalf = this.currentDate < 15 ? 'FIRST Half' : 'SECOND Half'
      return `Year: ${this.currentYear} / Month: ${this.currentMonth} / ${monthHalf}`
    },
    fiscalYear () {
      const current = new Date(Date.UTC(this.currentYear, this.currentMonth - 1, this.currentDate))
      const fiscalYearStart = new Date(Date.UTC(current.getUTCFullYear(), 3, 1))
      return current >= fiscalYearStart ? `FY${current.getUTCFullYear()}` : `FY${current.getUTCFullYear() - 1}`
    },
    totalWorkHours () {
      return this.sumTimes(this.dates.map(date => date.hours))
    },
    totalOvertime () {
      return this.sumTimes(this.dates.map(date => date.overtime))
    },
    totalAbsence () {
      return this.sumTimes(this.dates.map(date => date.absence))
    },
    totalPaidLeave () {
      return this.sumTimes(this.dates.map(date => date.paidLeave))
    },
    totalOther () {
      return this.sumTimes(this.dates.map(date => date.other))
    },
    formattedUsedPTO () {
      return this.hoursToDaysAndTimeString(this.usedPTOHours)
    },
    formattedRemainingPTO () {
      return this.hoursToDaysAndTimeString(this.remainingPTOHours)
    },
    formattedTotalPTO () {
      return this.hoursToDaysAndTimeString(this.totalPTOHours)
    },
    formattedUsedSickLeave () {
      return this.hoursToDaysAndTimeString(this.usedSickLeaveHours)
    },
    formattedRemainingSickLeave () {
      return this.hoursToDaysAndTimeString(this.remainingSickLeaveHours)
    },
    formattedTotalSickLeave () {
      return this.hoursToDaysAndTimeString(this.totalSickLeaveHours)
    }
  },
  methods: {
    checkAuth () {
      const authToken = localStorage.getItem('authToken')
      const employeeId = localStorage.getItem('employeeId')
      if (!authToken || !employeeId) {
        this.$router.push({ name: 'login' })
      } else {
        this.fetchEmployeeData(employeeId)
      }
    },
    fetchEmployeeData (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/employee/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.selectedEmployee.id = response.data.ID
          this.selectedEmployee.name = response.data.Username
          this.fetchEmployees()
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('employeeId')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fetchEmployees () {
      axios.get('https://internal.cnexco.com:3000/employees', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.employees = response.data.sort((a, b) => a.Username.localeCompare(b.Username))

          if (this.employees.length > 0) {
            this.selectedSubordinate = this.employees[0].Id
            this.fetchAttendanceData(this.selectedSubordinate)
            this.fetchEmployeePTO(this.selectedSubordinate)
            this.fetchEmployeeSickLeave(this.selectedSubordinate)
            this.fetchOvertimeOrders(this.selectedSubordinate) // OT Orderの取得
          }
        })
        .catch(error => {
          console.error('Error fetching employee data:', error)
        })
    },
    fetchAllAttendanceData () {
      const requests = this.employees.map(employee => {
        return this.fetchAttendanceData(employee.Id)
      })
      Promise.all(requests)
        .then(() => {
          console.log('All attendance data fetched')
        })
        .catch(error => {
          console.error('Error fetching all attendance data:', error)
        })
    },
    fetchAttendanceData (employeeId) {
      const month = `${this.currentYear}-${String(this.currentMonth).padStart(2, '0')}`
      const startDate = this.currentDate < 15 ? `${month}-01` : `${month}-16`
      const endDate = this.currentDate < 15 ? `${month}-15` : `${month}-${new Date(this.currentYear, this.currentMonth, 0).getDate()}`

      return axios.get(`https://internal.cnexco.com:3000/supervisor/attendance/${employeeId}/${startDate}/${endDate}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          const attendanceData = response.data
          this.attendanceData = { ...this.attendanceData, [employeeId]: attendanceData }
          if (employeeId === this.selectedSubordinate) {
            this.updateDatesWithAttendance(attendanceData)
            this.fetchEmployeePTO(employeeId)
            this.fetchEmployeeSickLeave(employeeId)
            this.fetchOvertimeOrders(employeeId) // OT Orderの取得
          }
        })
        .catch(error => {
          console.error('Error fetching attendance data:', error)
        })
    },
    fetchEmployeePTO (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/pto/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)
          const ptoData = response.data.find(data => data.Year === fiscalYearNumber)
          if (ptoData) {
            this.totalPTOHours = ptoData.PTO * 7.5
            this.fetchAnnualPTOData(employeeId)
          } else {
            this.totalPTOHours = 0
            this.usedPTOHours = 0
            this.remainingPTOHours = 0
          }
        })
        .catch(error => {
          console.error('Error fetching PTO data:', error)
        })
    },
    fetchAnnualPTOData (employeeId) {
      const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)

      axios.get(`https://internal.cnexco.com:3000/ptoapprove/${employeeId}/year/${fiscalYearNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          const ptoUsedData = response.data
          this.calculateUsedPTO(ptoUsedData)
        })
        .catch(error => {
          console.error('Error fetching PTO approval data:', error)
        })
    },
    calculateUsedPTO (ptoUsedData) {
      this.usedPTOHours = ptoUsedData.reduce((acc, date) => acc + this.timeToHours(date.PTO), 0)
      this.calculateRemainingPTO()
    },
    calculateRemainingPTO () {
      this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours
    },
    fetchEmployeeSickLeave (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/sickleave/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)
          const sickLeaveData = response.data.find(data => data.Year === fiscalYearNumber)
          if (sickLeaveData) {
            this.totalSickLeaveHours = sickLeaveData.SickLeave * 7.5
            this.fetchAnnualSickLeaveData(employeeId)
          } else {
            this.totalSickLeaveHours = 0
            this.usedSickLeaveHours = 0
            this.remainingSickLeaveHours = 0
          }
        })
        .catch(error => {
          console.error('Error fetching Sick Leave data:', error)
        })
    },
    fetchAnnualSickLeaveData (employeeId) {
      const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)

      axios.get(`https://internal.cnexco.com:3000/sickleaveapprove/${employeeId}/year/${fiscalYearNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          const attendanceData = response.data
          this.calculateUsedSickLeave(attendanceData)
        })
        .catch(error => {
          console.error('Error fetching Sick Leave approval data:', error)
        })
    },
    calculateUsedSickLeave (attendanceData) {
      this.usedSickLeaveHours = attendanceData.reduce((acc, date) => acc + this.timeToHours(date.SickLeave), 0)
      this.calculateRemainingSickLeave()
    },
    calculateRemainingSickLeave () {
      this.remainingSickLeaveHours = this.totalSickLeaveHours - this.usedSickLeaveHours
    },
    fetchOvertimeOrders (employeeId) {
      const month = `${this.currentYear}-${String(this.currentMonth).padStart(2, '0')}`
      const startDate = this.currentDate < 15 ? `${month}-01` : `${month}-16`
      const endDate = this.currentDate < 15 ? `${month}-15` : `${month}-${new Date(this.currentYear, this.currentMonth, 0).getDate()}`

      return axios.get(`https://internal.cnexco.com:3000/overtime/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        },
        params: {
          startDate,
          endDate
        }
      })
        .then(response => {
          const overtimeData = response.data
          this.updateDatesWithOvertime(overtimeData)
        })
        .catch(error => {
          console.error('Error fetching Overtime Order data:', error)
        })
    },
    updateDatesWithAttendance (attendanceData) {
      this.dates = attendanceData.map(date => {
        return {
          day: date.Date.split('T')[0],
          start: date.InTime ? date.InTime.slice(0, 5) : '',
          partialEnd: date.MidOutTime === '00:00:00' ? '' : date.MidOutTime ? date.MidOutTime.slice(0, 5) : '',
          partialStart: date.MidInTime === '00:00:00' ? '' : date.MidInTime ? date.MidInTime.slice(0, 5) : '',
          end: date.OutTime ? date.OutTime.slice(0, 5) : '',
          break: date.BreakTime ? date.BreakTime.slice(0, 5) : '0:00',
          paidLeave: date.PTO ? date.PTO.slice(0, 5) : '0:00',
          other: date.Others ? date.Others.slice(0, 5) : '0:00',
          hours: date.WorkHours ? date.WorkHours.slice(0, 5) : '',
          overtime: date.OvertimeHours ? date.OvertimeHours.slice(0, 5) : '',
          otOrder: '0:00', // 初期値
          absence: date.AbsentHours ? date.AbsentHours.slice(0, 5) : '',
          confirmed: date.SelfConf === 1,
          supervisorConfirmed: date.SupervisorConf === 1
        }
      })
        .sort((a, b) => new Date(a.day) - new Date(b.day))

      this.calculateAllTimes()
    },
    updateDatesWithOvertime (overtimeData) {
      overtimeData.forEach(ot => {
        const dateIndex = this.dates.findIndex(d => d.day === ot.Date.split('T')[0])
        if (dateIndex !== -1) {
          this.dates[dateIndex].otOrder = this.timeToHours(ot.OverTimeOrder) ? ot.OverTimeOrder.slice(0, 5) : '0:00'
        }
      })
      this.$forceUpdate()
    },
    calculateAllTimes () {
      this.dates.forEach(date => {
        date.break = this.calculateBreak(date.start, date.end, date.partialEnd, date.partialStart)
        date.hours = this.calculateWorkHours(date.start, date.partialEnd, date.partialStart, date.end, date.break, date.paidLeave, date.other)
        date.overtime = this.calculateOvertime(date.start, date.partialEnd, date.partialStart, date.end, date.break)
        date.absence = this.calculateAbsence(date.hours, date.paidLeave, date.other)
      })
      this.updateTotalTimes()
    },
    calculateBreak (start, end, partialEnd = null, partialStart = null) {
      const startTime = new Date(`1970-01-01T${start}Z`)
      const endTime = new Date(`1970-01-01T${end}Z`)
      const partialEndTime = partialEnd ? new Date(`1970-01-01T${partialEnd}Z`) : null
      const partialStartTime = partialStart ? new Date(`1970-01-01T${partialStart}Z`) : null

      if (!partialEnd && !partialStart) {
        const duration = (endTime - startTime) / (1000 * 60 * 60)
        return duration >= 6 ? '1:00' : '0:00'
      } else if (partialEnd === '00:00' && partialStart === '00:00') {
        const duration = (endTime - startTime) / (1000 * 60 * 60)
        return duration >= 6 ? '1:00' : '0:00'
      } else {
        if ((partialStartTime - partialEndTime) / (1000 * 60 * 60) >= 6 || (endTime - partialStartTime) / (1000 * 60 * 60) >= 6) {
          return '1:00'
        }
      }
      return '0:00'
    },
    calculateWorkHours (start, partialEnd, partialStart, end, breakTime, paidLeave, other) {
      if (start === '00:00' && end === '00:00') {
        return '00:00'
      }

      const startTime = new Date(`1970-01-01T${start}Z`)
      const partialEndTime = partialEnd ? new Date(`1970-01-01T${partialEnd}Z`) : null
      const partialStartTime = partialStart ? new Date(`1970-01-01T${partialStart}Z`) : null
      const endTime = new Date(`1970-01-01T${end}Z`)

      let duration = 0
      if (partialEndTime && partialStartTime) {
        duration = (partialEndTime - startTime + (endTime - partialStartTime)) / (1000 * 60 * 60)
      } else {
        duration = (endTime - startTime) / (1000 * 60 * 60)
      }

      const breakHours = this.timeToHours(breakTime)
      const workHours = duration - breakHours
      return this.hoursToTimeString(workHours)
    },
    calculateOvertime (start, partialEnd, partialStart, end, breakTime) {
      const startTime = new Date(`1970-01-01T${start}Z`)
      const partialEndTime = partialEnd ? new Date(`1970-01-01T${partialEnd}Z`) : null
      const partialStartTime = partialStart ? new Date(`1970-01-01T${partialStart}Z`) : null
      const endTime = new Date(`1970-01-01T${end}Z`)

      let workDuration = 0
      if (partialEndTime && partialStartTime) {
        workDuration = (partialEndTime - startTime + (endTime - partialStartTime)) / (1000 * 60 * 60)
      } else {
        workDuration = (endTime - startTime) / (1000 * 60 * 60)
      }

      const breakHours = this.timeToHours(breakTime)
      const overtime = Math.max(workDuration - breakHours - 7.5, 0)
      return this.hoursToTimeString(overtime)
    },
    calculateAbsence (workHours, paidLeave, other) {
      const requiredHours = 7.5 - this.timeToHours(paidLeave) - this.timeToHours(other)
      const workHoursFloat = this.timeToHours(workHours)
      const absence = requiredHours - workHoursFloat
      return this.hoursToTimeString(Math.max(absence, 0))
    },
    sumTimes (times) {
      const totalMinutes = times.reduce((acc, time) => {
        if (!time) return acc
        const [hours, minutes] = time.split(':').map(Number)
        return acc + hours * 60 + minutes
      }, 0)

      const h = Math.floor(totalMinutes / 60)
      const m = totalMinutes % 60
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
    },
    timeToHours (timeStr) {
      if (typeof timeStr !== 'string') return 0
      const parts = timeStr.split(':')
      return parseFloat(parts[0]) + parseFloat(parts[1]) / 60
    },
    hoursToDaysAndTimeString (hours) {
      const days = (hours / 7.5).toFixed(2)
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${days} Days (${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')})`
    },
    hoursToTimeString (hours) {
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
    },
    moveForward () {
      this.adjustDate(true)
      this.fetchAllAttendanceData()
    },
    moveBackward () {
      this.adjustDate(false)
      this.fetchAllAttendanceData()
    },
    adjustDate (forward) {
      const currentDate = new Date(this.currentYear, this.currentMonth - 1, this.currentDate)
      if (forward) {
        if (this.currentDate < 15) {
          this.currentDate = 16
        } else {
          currentDate.setMonth(currentDate.getMonth() + 1)
          this.currentYear = currentDate.getFullYear()
          this.currentMonth = currentDate.getMonth() + 1
          this.currentDate = 1
        }
      } else {
        if (this.currentDate > 15) {
          this.currentDate = 1
        } else {
          currentDate.setMonth(currentDate.getMonth() - 1)
          this.currentYear = currentDate.getFullYear()
          this.currentMonth = currentDate.getMonth() + 1
          this.currentDate = 16
        }
      }
    },
    fetchHolidays (year) {
      axios.get(`https://internal.cnexco.com:3000/holidays/${year}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.holidays = response.data
        })
        .catch(error => {
          console.error('Error fetching holidays:', error)
        })
    },
    progressBarStyle (employeeId) {
      const percentage = this.progressPercentage(employeeId)
      return {
        width: `${percentage}%`,
        backgroundColor: percentage === 100 ? 'green' : 'blue'
      }
    },
    progressTextStyle (employeeId) {
      const percentage = this.progressPercentage(employeeId)
      return {
        color: percentage === 100 ? 'green' : 'black',
        fontWeight: percentage === 100 ? 'bold' : 'normal'
      }
    },
    progressPercentage (employeeId) {
      const attendance = this.attendanceData[employeeId] || []
      const totalDays = this.calculateTotalDays()
      const confirmed = attendance.filter(date => date.SupervisorConf === 1 && !this.isWeekend(date.Date.split('T')[0]) && !this.isHoliday(date.Date.split('T')[0])).length
      return totalDays === 0 ? 0 : Math.round((confirmed / totalDays) * 100)
    },
    calculateTotalDays () {
      const startDate = new Date(this.currentYear, this.currentMonth - 1, this.currentDate < 15 ? 1 : 16)
      const endDate = new Date(this.currentYear, this.currentMonth - 1, this.currentDate < 15 ? 15 : new Date(this.currentYear, this.currentMonth, 0).getDate())
      let totalDays = 0
      let currentDate = new Date(startDate)

      while (currentDate <= endDate) {
        if (!this.isWeekend(currentDate.toISOString().split('T')[0]) && !this.isHoliday(currentDate.toISOString().split('T')[0])) {
          totalDays++
        }
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
      }
      return totalDays
    },
    isWeekend (dateStr) {
      const date = new Date(dateStr + 'T00:00:00Z')
      const day = date.getUTCDay()
      return day === 0 || day === 6
    },
    isHoliday (dateStr) {
      return this.holidays.some(holiday => holiday.holiday_date === dateStr)
    },
    updateTotalTimes () {
      this.$forceUpdate()
    },
    toggleSupervisorConfirmation (date) {
      date.supervisorConfirmed = !date.supervisorConfirmed
      this.updateSupervisorConfirmation(date)
    },
    updateSupervisorConfirmation (date) {
      const confirmationData = {
        EmployeeID: this.selectedSubordinate,
        Date: date.day,
        SupervisorConf: date.supervisorConfirmed ? 1 : 0
      }
      axios.put('https://internal.cnexco.com:3000/attendance/supervisor-confirm', confirmationData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Supervisor confirmation updated:', response.data)
          this.fetchAttendanceData(this.selectedSubordinate)
        })
        .catch(error => {
          console.error('Error updating supervisor confirmation:', error)
        })
    },
    downloadCSV (employeeId, employeeName) {
      const attendance = this.attendanceData[employeeId] || []
      if (!attendance.length) {
        console.error('No attendance data available for download.')
        return
      }

      const halfOfMonth = this.currentDate < 15 ? 'FirstHalf' : 'SecondHalf'
      const month = this.currentMonth.toString().padStart(2, '0')

      const csvContent = [
        ['Date', 'Start', 'Mid End', 'Mid Start', 'End', 'Break', 'PTO', 'Other', 'Hours', 'Overtime', 'OT Order', 'Absence', 'Self', 'Supervisor']
      ]
        .concat(attendance.map(data => [
          data.Date.split('T')[0],
          data.InTime || '',
          data.MidOutTime || '',
          data.MidInTime || '',
          data.OutTime || '',
          data.BreakTime || '',
          data.PTO || '',
          data.Others || '',
          data.WorkHours || '',
          data.OvertimeHours || '',
          data.OTOrder || '0:00',
          data.AbsentHours || '',
          data.SelfConf ? 'Confirmed' : '',
          data.SupervisorConf ? 'Confirmed' : ''
        ]))
        .map(e => e.join(',')).join('\n')

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.setAttribute('href', url)
      link.setAttribute('download', `${employeeId}_${employeeName}_${this.currentYear}-${month}_${halfOfMonth}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      URL.revokeObjectURL(url)
    }
  },
  watch: {
    selectedSubordinate (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchAttendanceData(newVal)
        this.fetchEmployeePTO(newVal)
        this.fetchEmployeeSickLeave(newVal)
        this.fetchOvertimeOrders(newVal)
      }
    },
    fiscalYear (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.selectedSubordinate) {
          this.fetchAnnualPTOData(this.selectedSubordinate)
          this.fetchAnnualSickLeaveData(this.selectedSubordinate)
        }
      }
    }
  },
  created () {
    this.checkAuth()
    this.fetchHolidays(this.currentYear)
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px;
}

.date-switcher, .employee-info, .date-table {
  margin-bottom: 20px;
}

.date-switcher {
  display: flex;
  align-items: center;
}

.date-switcher button {
  background: none;
  border: none;
}

.date-table table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.confirmed {
  background-color: #e0e0e0;
}

.full-width {
  width: 100%;
}

.employees-list ul {
  list-style-type: none;
  padding: 0;
}

.subordinate-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.progress-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.progress-bar-container {
  width: 100px;
  height: 10px;
  background-color: #ddd;
  margin-right: 5px;
}

.progress-bar {
  height: 100%;
}

.progress-text {
  margin-left: 5px;
}

.csv-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.csv-button:hover {
  background-color: #0056b3;
}

.pto-section {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.pto-table {
  flex: 0 0 auto;
  margin-right: 20px;
}

.pto-table table {
  border-collapse: collapse;
}

.pto-table th, .pto-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.pto-table th {
  background-color: #f2f2f2;
}

.date-switcher {
  display: flex;
  align-items: center;
}

.date-switcher span {
  font-size: 1em;
  font-weight: bold;
  margin: 0 10px;
}

.date-switcher button {
  font-size: 1em;
  font-weight: bold;
}

.remain-pto-negative {
  color: red;
  font-weight: bold;
}
</style>

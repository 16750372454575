<template>
    <div class="change-password-container">
      <h2>Change Password</h2>
      <form @submit.prevent="handleChangePassword">
        <div class="form-group">
          <label for="userId">User ID:</label>
          <input type="text" id="userId" v-model="userId" required>
        </div>
        <div class="form-group">
          <label for="currentPassword">Current Password:</label>
          <input type="password" id="currentPassword" v-model="currentPassword" required>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password:</label>
          <input type="password" id="newPassword" v-model="newPassword" required>
        </div>
        <div class="form-group">
          <label for="confirmNewPassword">Confirm New Password:</label>
          <input type="password" id="confirmNewPassword" v-model="confirmNewPassword" required>
        </div>
        <button type="submit">Change Password</button>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </form>
      <router-link to="/">Back to Login</router-link>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      userId: '',
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      errorMessage: ''
    }
  },
  methods: {
    handleChangePassword () {
      if (this.newPassword !== this.confirmNewPassword) {
        this.errorMessage = 'New passwords do not match.'
        return
      }

      axios.post('https://internal.cnexco.com:3000/change-password', {
        Id: this.userId,
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      })
        .then(response => {
          alert('Password changed successfully.')
          this.$router.push({ name: 'UserLogin' })
        })
        .catch(error => {
          this.errorMessage = error.response?.data?.message || 'Error changing password. Please try again.'
          console.error('Error changing password:', error)
        })
    }
  }
}
</script>

  <style scoped>
  .change-password-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  .form-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }

  input[type="password"], input[type="text"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-right: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }

  button:hover {
    background-color: #0056b3;
  }

  .error {
    color: red;
    text-align: center;
  }

  a {
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    text-align: center;
    transition: color 0.3s;
  }

  a:hover {
    color: #0056b3;
  }
  </style>

<template>
  <div class="content">
    <!-- 社員情報 -->
    <div class="employee-info">
      <h2>Employee ID: {{ selectedEmployee.id }} - Name: {{ selectedEmployee.name }}</h2>
    </div>

    <!-- 年度の選択 -->
    <div class="fiscal-year-selector">
      <label for="fiscalYear" class="fiscal-year-label">Select Fiscal Year: </label>
      <select v-model="selectedFiscalYear" @change="fetchPTOApprovalData(selectedSubordinate)" class="fiscal-year-select">
        <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
      </select>
    </div>

    <!-- 部下の選択 -->
    <div class="subordinates-list">
      <h3>Subordinate List</h3>
      <ul>
        <li v-for="subordinate in subordinates" :key="subordinate.Id" class="subordinate-item">
          <input type="radio" :value="subordinate.Id" v-model="selectedSubordinate" @change="fetchPTOApprovalData(subordinate.Id)">
          <span>{{ subordinate.Username }}</span>
        </li>
      </ul>
    </div>

    <!-- PTO情報 -->
    <div class="pto-section">
      <div class="pto-table">
        <table>
          <thead>
            <tr>
              <th>{{ fiscalYear }} PTO</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ formattedTotalPTO }}</td>
            </tr>
            <tr>
              <td>Used</td>
              <td>{{ formattedUsedPTO }}</td>
            </tr>
            <tr>
              <td :class="{'remain-pto-negative': remainingPTOHours <= 0}">Remain</td>
              <td :class="{'remain-pto-negative': remainingPTOHours <= 0}">{{ formattedRemainingPTO }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Date Table -->
    <div class="date-table full-width">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>PTO</th>
            <th>Self</th>
            <th>Supervisor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="date in dates" :key="date.ID"
  :class="{
    'confirmed': date.SelfConf,
    'supervisor-confirmed': date.SupervisorConf,
    'supervisor-unconfirmed': !date.SupervisorConf
  }">
<td>{{ date.Date.split('T')[0] }}</td>
<td>{{ date.PTO }}</td>
<td>{{ date.SelfConf ? 'Confirmed' : 'Not Confirmed' }}</td>
<td>
  <button
    @click="toggleSupervisorConfirmation(date)"
    :class="date.SupervisorConf ? 'btn-undo' : 'btn-confirm'">
    {{ date.SupervisorConf ? 'Undo' : 'Confirm' }}
  </button>
</td>
</tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      currentDate: new Date().getDate(),
      selectedEmployee: { id: '', name: '' },
      subordinates: [],
      selectedSubordinate: null,
      selectedFiscalYear: '',
      totalPTOHours: 0,
      usedPTOHours: 0,
      remainingPTOHours: 0,
      holidays: [],
      dates: [],
      availableYears: []
    }
  },
  computed: {
    fiscalYear () {
      const current = new Date(Date.UTC(this.currentYear, this.currentMonth - 1, this.currentDate))
      const fiscalYearStart = new Date(Date.UTC(current.getUTCFullYear(), 3, 1))
      return current >= fiscalYearStart ? `FY${current.getUTCFullYear()}` : `FY${current.getUTCFullYear() - 1}`
    },
    formattedUsedPTO () {
      return this.hoursToDaysAndTimeString(this.usedPTOHours)
    },
    formattedRemainingPTO () {
      return this.hoursToDaysAndTimeString(this.remainingPTOHours)
    },
    formattedTotalPTO () {
      return this.hoursToDaysAndTimeString(this.totalPTOHours)
    }
  },
  methods: {
    checkAuth () {
      const authToken = localStorage.getItem('authToken')
      const employeeId = localStorage.getItem('employeeId')
      if (!authToken || !employeeId) {
        this.$router.push({ name: 'login' })
      } else {
        this.fetchEmployeeData(employeeId)
      }
    },
    fetchEmployeeData (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/employee/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.selectedEmployee.id = response.data.ID
          this.selectedEmployee.name = response.data.Username
          this.initializeFiscalYear()
          this.fetchSubordinates()
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('employeeId')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fetchSubordinates () {
      axios.get(`https://internal.cnexco.com:3000/subordinates/${this.selectedEmployee.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.subordinates = response.data.sort((a, b) => {
            const nameA = a.Username.toUpperCase()
            const nameB = b.Username.toUpperCase()
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
          })

          if (this.subordinates.length > 0) {
            this.selectedSubordinate = this.subordinates[0].Id
            this.fetchPTOApprovalData(this.selectedSubordinate)
          }
        })
        .catch(error => {
          console.error('Error fetching subordinate data:', error)
        })
    },
    fetchPTOApprovalData (employeeId) {
      const fiscalYearNumber = parseInt(this.selectedFiscalYear.replace('FY', ''), 10)

      axios.get(`https://internal.cnexco.com:3000/pto/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          const ptoData = response.data.find(data => data.Year === fiscalYearNumber)
          if (ptoData) {
            const ptoDays = parseFloat(ptoData.PTO)
            this.totalPTOHours = ptoDays * 7.5
            this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours
          } else {
            this.totalPTOHours = 0
            this.remainingPTOHours = 0
          }
        })
        .catch(error => {
          console.error('Error fetching PTO data:', error)
        })

      axios.get(`https://internal.cnexco.com:3000/ptoapprove/${employeeId}/year/${fiscalYearNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.dates = response.data.map(date => {
            return {
              ...date,
              Date: date.Date.split('T')[0],
              PTO: date.PTO.slice(0, 5)
            }
          })

          this.dates.sort((a, b) => new Date(a.Date) - new Date(b.Date))

          this.calculateUsedPTO()
        })
        .catch(error => {
          console.error('Error fetching PTO approval data:', error)
        })
    },
    initializeFiscalYear () {
      const currentFiscalYear = this.fiscalYear
      this.availableYears = []
      const startYear = 2023

      for (let year = startYear; year <= this.currentYear; year++) {
        this.availableYears.push(`FY${year}`)
      }

      this.selectedFiscalYear = currentFiscalYear
      this.fetchPTOApprovalData(this.selectedSubordinate)
    },
    calculateUsedPTO () {
      this.usedPTOHours = this.dates.reduce((total, date) => {
        return total + this.timeToHours(date.PTO)
      }, 0)
      this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours
    },
    toggleSupervisorConfirmation (date) {
      const updatedSupervisorConf = date.SupervisorConf ? 0 : 1

      const recordData = {
        EmployeeID: this.selectedSubordinate,
        Date: date.Date,
        SupervisorConf: updatedSupervisorConf
      }

      axios.put('https://internal.cnexco.com:3000/ptoapprove/supervisor', recordData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          date.SupervisorConf = updatedSupervisorConf
        })
        .catch(error => {
          console.error('Error updating supervisor confirmation:', error)
        })
    },
    timeToHours (timeStr) {
      if (typeof timeStr !== 'string') return 0
      const parts = timeStr.split(':')
      return parseFloat(parts[0]) + parseFloat(parts[1]) / 60
    },
    hoursToDaysAndTimeString (hours) {
      const days = (hours / 7.5).toFixed(2)
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${days} Days (${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')})`
    }
  },
  watch: {
    selectedSubordinate (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchPTOApprovalData(newVal)
      }
    },
    fiscalYear (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.selectedSubordinate) {
          this.fetchPTOApprovalData(this.selectedSubordinate)
        }
      }
    }
  },
  created () {
    this.checkAuth()
  }
}
</script>

<style scoped>
.content {
display: flex;
flex-direction: column;
align-items: start;
margin: 20px;
}

.employee-info {
margin-bottom: 20px;
}

.fiscal-year-selector {
margin-bottom: 20px;
}

.fiscal-year-label {
  font-size: 1.2em; /* ラベルのスタイル */
}

.fiscal-year-select {
  font-size: 1.5em; /* 選択肢のスタイル */
}

.subordinates-list ul {
list-style-type: none;
padding: 0;
}

.subordinate-item {
display: flex;
align-items: center;
margin-bottom: 5px;
}

.pto-section {
display: flex;
align-items: flex-end;
justify-content: flex-start;
width: 100%;
margin-bottom: 20px;
}

.pto-table {
flex: 0 0 auto;
margin-right: 20px;
}

.pto-table table {
border-collapse: collapse;
}

.pto-table th, .pto-table td {
text-align: left;
padding: 8px;
border-bottom: 1px solid #ddd;
white-space: nowrap;
}

.pto-table th {
background-color: #f2f2f2;
}

.remain-pto-negative {
color: red;
font-weight: bold;
}

.date-table {
width: 100%;
overflow-x: auto;
}

.date-table table {
width: 100%;
border-collapse: collapse;
}

th,
td {
text-align: center;
padding: 8px;
border-bottom: 1px solid #ddd;
}

th {
background-color: #f2f2f2;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.confirmed {
background-color: #e0e0e0;
}

.supervisor-unconfirmed {
background-color: white;
}

.btn-confirm {
background-color: red;
color: white;
padding: 5px 10px;
border: none;
border-radius: 3px;
}

.btn-undo {
background-color: green;
color: white;
padding: 5px 10px;
border: none;
border-radius: 3px;
}

.supervisor-confirmed-text {
color: green;
font-weight: bold;
}

.full-width {
width: 100%;
}
</style>

// src/store/index.js
import { createStore } from 'vuex'

const store = createStore({
  state () {
    return {
      authToken: null,
      systemAccessLevel: null, // SystemAccessLevelの追加
      inTime: null, // InTimeの追加
      outTime: null // OutTimeの追加
    }
  },
  mutations: {
    setAuthToken (state, token) {
      state.authToken = token
    },
    setSystemAccessLevel (state, level) { // SystemAccessLevelのミューテーション
      state.systemAccessLevel = level
    },
    setInTime (state, inTime) { // InTimeのミューテーション
      state.inTime = inTime
    },
    setOutTime (state, outTime) { // OutTimeのミューテーション
      state.outTime = outTime
    }
  },
  actions: {
    authenticateUser ({ commit }, { token, level, inTime, outTime }) { // level, inTime, outTimeを追加
      commit('setAuthToken', token)
      commit('setSystemAccessLevel', level) // SystemAccessLevelをコミット
      commit('setInTime', inTime) // InTimeをコミット
      commit('setOutTime', outTime) // OutTimeをコミット
    }
  }
})

export default store

<template>
  <div class="content">
    <!-- Employee Information -->
    <div class="employee-info">
      <h2>Employee ID: {{ selectedEmployee.id }} - Name: {{ selectedEmployee.name }}</h2>
    </div>

    <!-- Fiscal Year Selection -->
    <div class="fiscal-year-selection">
      <label for="fiscalYear">Select Fiscal Year:</label>
      <select v-model="selectedFiscalYear" @change="fetchAllData">
        <option v-for="year in fiscalYears" :key="year" :value="year">{{ year }}</option>
      </select>
    </div>

    <!-- PTO Information -->
    <div class="pto-table">
      <table>
        <thead>
          <tr>
            <th>{{ selectedFiscalYear }} PTO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total</td>
            <td>{{ formattedTotalPTO }}</td>
          </tr>
          <tr>
            <td>Used</td>
            <td>{{ formattedUsedPTO }}</td>
          </tr>
          <tr>
            <td>Remain</td>
            <td>{{ formattedRemainingPTO }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Table with Dates -->
    <div class="date-table full-width">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>PTO</th>
            <th>Self</th>
            <th>Supervisor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="date in dates" :key="date.ID" :class="{ 'confirmed': date.SelfConf, 'supervisor-confirmed': date.SupervisorConf }">
            <td>{{ date.Date.split('T')[0] }}</td>
            <td>
              <select v-model="date.PTO" @change="handleLeaveChange(date, 'PTO')" :disabled="date.future || date.SelfConf || date.SupervisorConf">
                <option v-for="option in timeOptions" :key="option" :value="option">{{ option }}</option>
              </select>
            </td>
            <td>
              <button @click="toggleConfirmation(date)" :disabled="date.future || date.SupervisorConf">{{ date.SelfConf ? 'Undo' : 'Confirm' }}</button>
            </td>
            <td>
              <span v-if="date.SupervisorConf" class="supervisor-confirmed-text">Confirmed</span>
            </td>
          </tr>
          <!-- Row for adding new record -->
          <tr>
            <td>
              <input type="date" v-model="newRecord.Date" />
            </td>
            <td>
              <select v-model="newRecord.PTO">
                <option v-for="option in timeOptions" :key="option" :value="option">{{ option }}</option>
              </select>
            </td>
            <td>
              <button @click="addNewRecord">{{ newRecord.SelfConf ? 'Undo' : 'Confirm' }}</button>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import { mapState } from 'vuex'

export default {
  data () {
    const now = moment.tz('America/Chicago')
    const currentYear = now.year()
    return {
      currentYear: currentYear,
      currentMonth: now.month() + 1,
      currentDate: now.date(),
      selectedEmployee: { id: '', name: '' },
      dates: [],
      newRecord: {
        Date: '',
        PTO: '00:00',
        SelfConf: false
      },
      timeOptions: [],
      totalPTOHours: 0,
      usedPTOHours: 0,
      remainingPTOHours: 0,
      previousPTOValues: {},
      holidays: [],
      fiscalYears: this.generateFiscalYears(currentYear),
      selectedFiscalYear: `FY${currentYear}`
    }
  },
  computed: {
    ...mapState(['inTime', 'outTime']),
    formattedUsedPTO () {
      return this.hoursToDaysAndTimeString(this.usedPTOHours)
    },
    formattedRemainingPTO () {
      return this.hoursToDaysAndTimeString(this.remainingPTOHours)
    },
    formattedTotalPTO () {
      return this.hoursToDaysAndTimeString(this.totalPTOHours)
    }
  },
  watch: {
    selectedFiscalYear (newFiscalYear) {
      this.fetchAllData()
    }
  },
  methods: {
    checkAuth () {
      const authToken = localStorage.getItem('authToken')
      const employeeId = localStorage.getItem('employeeId')
      if (!authToken || !employeeId) {
        this.$router.push({ name: 'login' })
      } else {
        this.fetchEmployeeData(employeeId)
      }
    },
    fetchEmployeeData (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/employee/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Employee data fetched:', response.data)
          this.selectedEmployee.id = response.data.ID
          this.selectedEmployee.name = response.data.Username
          this.fetchAllData()
        })
        .catch(error => {
          console.error('Error fetching employee data:', error)
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('employeeId')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fetchAllData () {
      this.fetchPTOData()
      this.fetchHolidays(this.currentYear)
      this.fetchPTOApprovalData(this.selectedEmployee.id)
    },
    fetchPTOData () {
      axios.get(`https://internal.cnexco.com:3000/pto/${this.selectedEmployee.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('PTO data fetched:', response.data)
          const fiscalYearNumber = parseInt(this.selectedFiscalYear.replace('FY', ''), 10)
          const ptoData = response.data.find(data => data.Year === fiscalYearNumber)
          if (ptoData) {
            this.totalPTOHours = ptoData.PTO * 7.5
            this.calculateUsedPTO(response.data)
            this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours
          } else {
            this.totalPTOHours = 0
            this.usedPTOHours = 0
            this.remainingPTOHours = 0
          }
        })
        .catch(error => {
          console.error('Error fetching PTO data:', error)
        })
    },
    fetchPTOApprovalData (employeeId) {
      const fiscalYearNumber = parseInt(this.selectedFiscalYear.replace('FY', ''), 10)
      axios.get(`https://internal.cnexco.com:3000/ptoapprove/${employeeId}/year/${fiscalYearNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('PTO approval data fetched:', response.data)
          this.dates = response.data.map(date => {
            return {
              ...date,
              Date: date.Date.split('T')[0],
              PTO: date.PTO.slice(0, 5)
            }
          })

          // ここでDateの昇順にソートします
          this.dates.sort((a, b) => new Date(a.Date) - new Date(b.Date))

          this.calculateUsedPTO() // PTO approval dataからUsed PTOを計算
        })
        .catch(error => {
          console.error('Error fetching PTO approval data:', error)
        })
    },
    fetchHolidays (year) {
      axios.get(`https://internal.cnexco.com:3000/holidays/${year}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.holidays = response.data
        })
        .catch(error => {
          console.error('Error fetching holidays:', error)
        })
    },
    calculateUsedPTO () {
      this.usedPTOHours = this.dates.reduce((total, record) => {
        return total + this.timeStringToHours(record.PTO)
      }, 0)
      this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours
    },
    handleLeaveChange (date, field) {
      const previousValue = this.previousPTOValues[date.Date] || 0
      const newValue = this.timeStringToHours(date[field])

      const currentAbsenceWithoutLeave = date.start ? this.calculateAbsenceWithoutLeave(date) : '7:30'
      const totalLeaveAndOther = this.timeStringToHours(date.PTO) + this.timeStringToHours(date.other)

      if (totalLeaveAndOther > this.timeStringToHours(currentAbsenceWithoutLeave)) {
        alert(`Total of PTO and Other cannot exceed Absence. Maximum: ${currentAbsenceWithoutLeave}`)
        date.PTO = '00:00'
        date.other = '00:00'
        date.absence = currentAbsenceWithoutLeave
      } else {
        date.absence = this.hoursToTimeString(this.timeStringToHours(currentAbsenceWithoutLeave) - totalLeaveAndOther)
      }

      const difference = newValue - previousValue
      this.usedPTOHours += difference
      this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours

      this.previousPTOValues[date.Date] = newValue

      this.recalculateUsedPTO()
    },
    recalculateUsedPTO () {
      this.usedPTOHours = this.dates.reduce((total, date) => {
        return total + this.timeStringToHours(date.PTO)
      }, 0)
      this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours
    },
    toggleConfirmation (date) {
      if (!date.SelfConf) {
        this.addRecord(date)
      } else {
        this.deleteRecord(date)
      }
    },
    addRecord (date) {
      if (!date.Date || date.PTO === '00:00') {
        alert('Date and PTO must be provided and PTO cannot be 00:00.')
        return
      }

      if (this.exceedsDailyLimit(date.Date, date.PTO)) {
        alert('Total PTO for the date exceeds 7:30 hours. Please adjust the PTO time.')
        return
      }

      const recordData = {
        EmployeeID: this.selectedEmployee.id,
        Date: date.Date,
        PTO: date.PTO || '00:00:00',
        SelfConf: 1
      }
      axios.post('https://internal.cnexco.com:3000/ptoapprove', recordData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Record added:', response.data)
          this.fetchPTOApprovalData(this.selectedEmployee.id)
        })
        .catch(error => {
          console.error('Error adding record:', error)
        })
    },
    deleteRecord (date) {
      const recordData = {
        EmployeeID: this.selectedEmployee.id,
        Date: date.Date
      }
      axios.delete('https://internal.cnexco.com:3000/ptoapprove', {
        data: recordData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Record deleted:', response.data)
          this.fetchPTOApprovalData(this.selectedEmployee.id)
        })
        .catch(error => {
          console.error('Error deleting record:', error)
        })
    },
    addNewRecord () {
      if (!this.newRecord.Date || this.newRecord.PTO === '00:00') {
        alert('Date and PTO must be provided and PTO cannot be 00:00.')
        return
      }

      if (this.exceedsDailyLimit(this.newRecord.Date, this.newRecord.PTO)) {
        alert('Total PTO for the date exceeds 7:30 hours. Please adjust the PTO time.')
        return
      }

      const newRecordData = {
        EmployeeID: this.selectedEmployee.id,
        Date: this.newRecord.Date,
        PTO: this.newRecord.PTO || '00:00:00',
        SelfConf: 1
      }
      axios.post('https://internal.cnexco.com:3000/ptoapprove', newRecordData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('New record added:', response.data)
          this.fetchPTOApprovalData(this.selectedEmployee.id)
          this.newRecord = { Date: '', PTO: '00:00', SelfConf: false }
        })
        .catch(error => {
          console.error('Error adding new record:', error)
        })
    },
    exceedsDailyLimit (date, newPTO) {
      let totalPTOForDate = this.dates.reduce((total, record) => {
        if (record.Date === date) {
          return total + this.timeStringToHours(record.PTO)
        }
        return total
      }, 0)
      totalPTOForDate += this.timeStringToHours(newPTO)
      return totalPTOForDate > 7.5
    },
    generateTimeOptions (maxTime) {
      const maxHours = this.timeStringToHours(maxTime)
      const options = []
      for (let i = 0; i <= maxHours; i += 0.5) {
        options.push(this.hoursToTimeString(i))
      }
      return options
    },
    timeStringToHours (timeStr) {
      if (typeof timeStr !== 'string') return 0
      const parts = timeStr.split(':')
      return parseFloat(parts[0]) + parseFloat(parts[1]) / 60
    },
    hoursToTimeString (hours) {
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')}`
    },
    hoursToDaysAndTimeString (hours) {
      const days = (hours / 7.5).toFixed(2)
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${days} Days (${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')})`
    },
    sumTimes (times) {
      const totalMinutes = times.reduce((acc, time) => {
        if (!time || time === '0:00') return acc
        const [hours, minutes] = time.split(':').map(Number)
        return acc + hours * 60 + minutes
      }, 0)

      const h = Math.floor(totalMinutes / 60)
      const m = totalMinutes % 60
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
    },
    calculateAbsenceWithoutLeave (date) {
      const requiredHours = 7.5
      const workHoursFloat = this.timeStringToHours(this.calculateWorkHours(date.start, date.partialEnd, date.partialStart, date.end, date.break, '00:00', '00:00'))
      const absence = requiredHours - workHoursFloat
      return this.hoursToTimeString(Math.max(absence, 0))
    },
    calculateWorkHours (start, partialEnd, partialStart, end, breakTime, PTO, other) {
      if (start === '00:00' && end === '00:00') {
        return '00:00'
      }

      const startTime = new Date(`1970-01-01T${start}Z`)
      const partialEndTime = partialEnd ? new Date(`1970-01-01T${partialEnd}Z`) : null
      const partialStartTime = partialStart ? new Date(`1970-01-01T${partialStart}Z`) : null
      const endTime = new Date(`1970-01-01T${end}Z`)

      let duration = 0
      if (partialEndTime && partialStartTime) {
        duration = (partialEndTime - startTime + (endTime - partialStartTime)) / (1000 * 60 * 60)
      } else {
        duration = (endTime - startTime) / (1000 * 60 * 60)
      }

      const breakHours = this.timeStringToHours(breakTime)
      const workHours = duration - breakHours
      return this.hoursToTimeString(workHours)
    },
    calculateWorkHoursForBlankStart (PTO, other) {
      const workHours = 0
      return this.hoursToTimeString(workHours)
    },
    calculateAllTimes () {
      this.dates.forEach(date => {
        if (!date.future) {
          date.hours = this.calculateWorkHours(date.start, date.partialEnd, date.partialStart, date.end, date.break, date.PTO, date.other)
        }
      })
    },
    generateFiscalYears (currentYear) {
      const startYear = 2023
      const fiscalYears = []
      for (let i = startYear; i <= currentYear; i++) {
        fiscalYears.push(`FY${i}`)
      }
      return fiscalYears
    }
  },
  created () {
    this.timeOptions = this.generateTimeOptions('7:30')
    this.checkAuth()
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px;
}

.pto-and-date-switcher {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}

.fiscal-year-selection {
  margin-bottom: 20px;
  font-size: 1.2em;
}

.fiscal-year-selection select {
  font-size: 1.2em;
}

.employee-info, .date-table {
  margin-bottom: 20px;
}

.pto-table {
  margin-top: 10px;
}

.date-table {
  width: 100%;
  overflow-x: auto;
}

.date-table table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

th span {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8vw;
}

th span.adjusted {
  font-size: 0.8vw;
}

.confirmed {
  background-color: #e0e0e0;
}

.supervisor-confirmed-text {
  color: green;
  font-weight: bold;
}

.full-width {
  width: 100%;
}
</style>

<template>
    <div class="content">
      <!-- 社員情報 -->
      <div class="employee-info">
        <h2>Employee ID: {{ selectedEmployee.id }} - Name: {{ selectedEmployee.name }}</h2>
      </div>

      <!-- 年度の選択 -->
      <div class="fiscal-year-selector">
        <label for="fiscalYear" class="fiscal-year-label">Select Fiscal Year: </label>
        <select id="fiscalYear" v-model="selectedFiscalYear" @change="fetchOverTimeOrders" class="fiscal-year-select">
        <option v-for="year in availableYears" :key="year" :value="year">FY{{ year }}</option>
        </select>
      </div>

      <!-- 部下の選択 -->
      <div class="subordinates-list">
        <h3>Subordinate List</h3>
        <ul>
          <li v-for="subordinate in subordinates" :key="subordinate.Id" class="subordinate-item">
            <input type="radio" :value="subordinate.Id" v-model="selectedSubordinate">
            <span>{{ subordinate.Username }}</span>
          </li>
        </ul>
      </div>

      <!-- Date Table -->
      <div class="date-table full-width">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>OverTime</th>
              <th>Supervisor</th>
            </tr>
          </thead>
          <tbody>
            <!-- サーバーから取得したレコード -->
            <tr v-for="date in dates" :key="date.ID">
              <td>{{ date.Date.split('T')[0] }}</td>
              <td>{{ date.OverTime }}</td>
              <td>
                <button
                  @click="toggleOrder(date)"
                  :class="date.SupervisorConf ? 'btn-undo' : 'btn-confirm'">
                  {{ date.SupervisorConf ? 'Undo' : 'Order' }}
                </button>
              </td>
            </tr>

            <!-- 新しいレコードの入力行 -->
            <tr>
              <td><input type="date" v-model="newRecord.Date"></td>
              <td>
                <select v-model="newRecord.OverTime">
                  <option v-for="time in timeOptions" :key="time" :value="time">{{ time }}</option>
                </select>
              </td>
              <td>
                <button class="btn-confirm" @click="orderNewOverTime">Order</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      currentYear: new Date().getFullYear(),
      selectedEmployee: { id: '', name: '' },
      subordinates: [],
      selectedSubordinate: null,
      dates: [],
      newRecord: {
        Date: '',
        OverTime: ''
      },
      selectedFiscalYear: this.getCurrentFiscalYear(),
      availableYears: this.generateAvailableYears(),
      timeOptions: this.generateTimeOptions()
    }
  },
  methods: {
    getCurrentFiscalYear () {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1
      return currentMonth >= 4 ? currentYear : currentYear - 1
    },
    generateAvailableYears () {
      const years = []
      for (let year = 2023; year <= this.getCurrentFiscalYear(); year++) {
        years.push(year)
      }
      return years
    },
    generateTimeOptions () {
      const times = []
      const time = new Date(1970, 0, 1, 0, 30)
      while (time.getHours() !== 0 || time.getMinutes() !== 0) {
        const hours = String(time.getHours()).padStart(2, '0')
        const minutes = String(time.getMinutes()).padStart(2, '0')
        times.push(`${hours}:${minutes}`)
        time.setMinutes(time.getMinutes() + 30)
      }
      times.push('24:00')
      return times
    },
    checkAuth () {
      const authToken = localStorage.getItem('authToken')
      const employeeId = localStorage.getItem('employeeId')
      if (!authToken || !employeeId) {
        this.$router.push({ name: 'login' })
      } else {
        this.fetchEmployeeData(employeeId)
      }
    },
    fetchEmployeeData (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/employee/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.selectedEmployee.id = response.data.ID
          this.selectedEmployee.name = response.data.Username
          this.fetchSubordinates()
        })
        .catch(error => {
          console.error('Error fetching employee data:', error)
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('employeeId')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fetchSubordinates () {
      axios.get(`https://internal.cnexco.com:3000/subordinates/${this.selectedEmployee.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.subordinates = response.data.sort((a, b) => {
            const nameA = a.Username.toUpperCase()
            const nameB = b.Username.toUpperCase()
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
          })

          if (this.subordinates.length > 0) {
            this.selectedSubordinate = this.subordinates[0].Id
            this.fetchOverTimeOrders()
          }
        })
        .catch(error => {
          console.error('Error fetching subordinate data:', error)
        })
    },
    orderNewOverTime () {
      if (!this.newRecord.Date || !this.newRecord.OverTime) {
        alert('Date and OverTime fields must not be blank to place an order.')
        return
      }

      const recordData = {
        EmployeeID: this.selectedSubordinate,
        Date: this.newRecord.Date,
        OverTimeOrder: this.newRecord.OverTime
      }

      axios.post('https://internal.cnexco.com:3000/overtime', recordData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Overtime order added:', response.data)
          this.fetchOverTimeOrders() // 登録後、データを再取得
          this.newRecord.Date = ''
          this.newRecord.OverTime = ''
        })
        .catch(error => {
          console.error('Error adding overtime order:', error)
        })
    },
    toggleOrder (date) {
      const isOrder = !date.SupervisorConf
      const recordData = {
        EmployeeID: this.selectedSubordinate,
        Date: date.Date.split('T')[0], // 日付を 'YYYY-MM-DD' 形式に変換
        OverTimeOrder: date.OverTime
      }

      if (isOrder) {
        axios.post('https://internal.cnexco.com:3000/overtime', recordData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          }
        })
          .then(response => {
            console.log('Overtime order added:', response.data)
            date.SupervisorConf = true
            this.fetchOverTimeOrders() // 登録後、データを再取得
          })
          .catch(error => {
            console.error('Error adding overtime order:', error)
          })
      } else {
        axios.delete('https://internal.cnexco.com:3000/overtime', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`
          },
          data: {
            EmployeeID: this.selectedSubordinate,
            Date: date.Date.split('T')[0] // 日付を 'YYYY-MM-DD' 形式に変換
          }
        })
          .then(response => {
            console.log('Overtime order deleted:', response.data)
            date.SupervisorConf = false
            this.fetchOverTimeOrders() // 削除後、データを再取得
          })
          .catch(error => {
            console.error('Error deleting overtime order:', error)
          })
      }
    },
    fetchOverTimeOrders () {
      const startDate = `${this.selectedFiscalYear}-04-01`
      const endDate = `${parseInt(this.selectedFiscalYear) + 1}-03-31`

      axios.get(`https://internal.cnexco.com:3000/overtime/${this.selectedSubordinate}?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.dates = response.data.map(date => ({
            ...date,
            OverTime: date.OverTimeOrder.slice(0, 5),
            SupervisorConf: true
          }))
        })
        .catch(error => {
          console.error('Error fetching overtime orders:', error)
        })
    }
  },
  watch: {
    selectedSubordinate (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchOverTimeOrders()
      }
    }
  },
  created () {
    this.checkAuth()
  }
}
</script>

  <style scoped>
  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 20px;
  }

  /* 年度選択 */
  .fiscal-year-selector {
  margin-bottom: 20px;
  }

  .fiscal-year-label {
  font-size: 1.2em; /* ラベルの文字サイズ */
  }

  .fiscal-year-select {
  font-size: 1.5em; /* 選択肢の文字サイズ */
  }

  .employee-info {
    margin-bottom: 20px;
  }

  .subordinates-list ul {
    list-style-type: none;
    padding: 0;
  }

  .subordinate-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .date-table {
    width: 100%;
    overflow-x: auto;
  }

  .date-table table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    text-align: center;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .confirmed {
    background-color: #e0e0e0;
  }

  .supervisor-unconfirmed {
    background-color: white;
  }

  .btn-confirm {
    background-color: red;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
  }

  .btn-undo {
    background-color: green;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
  }

  .supervisor-confirmed-text {
    color: green;
    font-weight: bold;
  }

  .full-width {
    width: 100%;
  }
  </style>

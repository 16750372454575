<template>
  <div class="content">
    <!-- Employee Information -->
    <div class="employee-info">
      <h2>Employee ID: {{ selectedEmployee.id }} - Name: {{ selectedEmployee.name }}</h2>
    </div>

    <!-- PTO and Sick Leave Information and Date Switcher in the Same Row -->
    <div class="pto-and-sickleave-and-date-switcher">
      <div class="pto-table">
        <table>
          <thead>
            <tr>
              <th colspan="2" style="text-align: left;">{{ fiscalYear }} PTO</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ formattedTotalPTO }}</td>
            </tr>
            <tr>
              <td>Used</td>
              <td>{{ formattedUsedPTO }}</td>
            </tr>
            <tr>
              <td>Remain</td>
              <td>{{ formattedRemainingPTO }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="sickleave-table">
        <table>
          <thead>
            <tr>
              <th colspan="2" style="text-align: left;">{{ fiscalYear }} Sick Leave</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total</td>
              <td>{{ formattedTotalSickLeave }}</td>
            </tr>
            <tr>
              <td>Used</td>
              <td>{{ formattedUsedSickLeave }}</td>
            </tr>
            <tr>
              <td>Remain</td>
              <td>{{ formattedRemainingSickLeave }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Date and Month Switcher -->
      <div class="date-switcher">
        <button @click="moveBackward">
          <b>&lt; Previous &boxv;&boxv;</b>
        </button>
        <span>{{ displayDate }}</span>
        <button @click="moveForward">
          <b>&boxv;&boxv; Next &gt;</b>
        </button>
      </div>
    </div>

    <!-- Table with Dates -->
    <div class="date-table full-width">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Start</th>
            <th>Mid End</th>
            <th>Mid Start</th>
            <th>End</th>
            <th>Break</th>
            <th>PTO</th>
            <th>Sick Lv</th> <!-- Sick Leaveの略語 -->
            <th>Hours</th>
            <th>OT</th> <!-- Overtimeの略語 -->
            <th>OT Ord</th> <!-- Overtime Orderの略語 -->
            <th>Abs</th> <!-- Absenceの略語 -->
            <th>Self</th>
            <th>Sup</th> <!-- Supervisorの略語 -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="date in filteredDates" :key="date.day" :class="{ 'confirmed': date.confirmed, 'supervisor-confirmed': date.supervisorConfirmed }">
            <td>{{ date.day }}</td>
            <td>
              <input type="time" v-model="date.start" @blur="handleTimeChange(date, 'start')" step="1800" :disabled="date.future || date.confirmed || date.supervisorConfirmed">
            </td>
            <td>
              <input type="time" v-model="date.partialEnd" @blur="handleTimeChange(date, 'partialEnd')" step="1800" :disabled="date.future || date.confirmed || date.supervisorConfirmed || !date.start">
            </td>
            <td>
              <input type="time" v-model="date.partialStart" @blur="handleTimeChange(date, 'partialStart')" step="1800" :disabled="date.future || date.confirmed || date.supervisorConfirmed || !date.start">
            </td>
            <td>
              <input type="time" v-model="date.end" @blur="handleTimeChange(date, 'end')" step="1800" :disabled="date.future || date.confirmed || date.supervisorConfirmed || !date.start">
            </td>
            <td>{{ date.break }}</td>
            <td>{{ date.paidLeave }}</td>
            <td>{{ date.sickLeave }}</td>
            <td>{{ date.hours }}</td>
            <td>{{ date.overtime }}</td>
            <td :class="{ 'highlighted': date.otOrder !== '0:00' }">{{ date.otOrder }}</td>
            <td>{{ date.absence }}</td>
            <td>
              <button @click="toggleConfirmation(date)" :disabled="date.future || date.supervisorConfirmed">{{ date.confirmed ? 'Undo' : 'Confirm' }}</button>
            </td>
            <td>
              <span v-if="date.supervisorConfirmed" class="supervisor-confirmed-text">Confirmed</span>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{ totalPaidLeave }}</td>
            <td>{{ totalSickLeave }}</td>
            <td>{{ totalWorkHours }}</td>
            <td>{{ totalOvertime }}</td>
            <td>{{ totalOTOrder }}</td>
            <td>{{ totalAbsence }}</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import { mapState } from 'vuex'

export default {
  data () {
    const now = moment.tz('America/Chicago')
    return {
      currentYear: now.year(),
      currentMonth: now.month() + 1,
      currentDate: now.date(),
      selectedEmployee: { id: '', name: '' },
      dates: [],
      totalSickLeaveHours: 0,
      usedSickLeaveHours: 0,
      remainingSickLeaveHours: 0,
      timeOptions: [],
      totalPTOHours: 0,
      usedPTOHours: 0,
      remainingPTOHours: 0,
      holidays: [],
      attendanceRecords: []
    }
  },
  computed: {
    ...mapState(['inTime', 'outTime']),
    displayDate () {
      const monthHalf = this.currentDate < 15 ? 'FIRST Half' : 'SECOND Half'
      return `Year: ${this.currentYear} / Month: ${this.currentMonth} / ${monthHalf}`
    },
    fiscalYear () {
      const current = new Date(Date.UTC(this.currentYear, this.currentMonth - 1, this.currentDate))
      const fiscalYearStart = new Date(Date.UTC(current.getUTCFullYear(), 3, 1))
      return current >= fiscalYearStart ? `FY${current.getUTCFullYear()}` : `FY${current.getUTCFullYear() - 1}`
    },
    filteredDates () {
      return this.dates.filter(date => {
        const dayOfWeek = new Date(date.day + 'T00:00:00Z').getUTCDay()
        const isHoliday = this.holidays.some(holiday => holiday.holiday_date === date.day)
        return dayOfWeek !== 0 && dayOfWeek !== 6 && !isHoliday
      })
    },
    totalWorkHours () {
      return this.sumTimes(this.filteredDates.map(date => date.hours))
    },
    totalOvertime () {
      return this.sumTimes(this.filteredDates.map(date => date.overtime))
    },
    totalAbsence () {
      return this.sumTimes(this.filteredDates.map(date => date.absence))
    },
    totalPaidLeave () {
      return this.sumTimes(this.filteredDates.map(date => date.paidLeave))
    },
    totalSickLeave () {
      return this.sumTimes(this.filteredDates.map(date => date.sickLeave || '0:00'))
    },
    totalOTOrder () {
      return this.sumTimes(this.filteredDates.map(date => date.otOrder || '0:00'))
    },
    formattedTotalSickLeave () {
      return this.hoursToDaysAndTimeString(this.totalSickLeaveHours)
    },
    formattedUsedSickLeave () {
      return this.hoursToDaysAndTimeString(this.usedSickLeaveHours)
    },
    formattedRemainingSickLeave () {
      return this.hoursToDaysAndTimeString(this.remainingSickLeaveHours)
    },
    formattedUsedPTO () {
      return this.hoursToDaysAndTimeString(this.usedPTOHours)
    },
    formattedRemainingPTO () {
      return this.hoursToDaysAndTimeString(this.remainingPTOHours)
    },
    formattedTotalPTO () {
      return this.hoursToDaysAndTimeString(this.totalPTOHours)
    }
  },
  watch: {
    fiscalYear (newFiscalYear) {
      this.fetchPTOData()
      this.fetchPTOApprovalData()
      this.fetchSickLeaveApprovalData() // Sick Leaveのデータを取得
      this.fetchSickLeaveData() // Sick LeaveのTotalを取得
      this.fetchOvertimeOrders() // Overtime Orderのデータを取得
      this.fetchAttendanceData()
    },
    currentYear (newYear, oldYear) {
      if (newYear !== oldYear) {
        this.fetchHolidays(newYear)
      }
    }
  },
  methods: {
    checkAuth () {
      const authToken = localStorage.getItem('authToken')
      const employeeId = localStorage.getItem('employeeId')
      if (!authToken || !employeeId) {
        this.$router.push({ name: 'login' })
      } else {
        this.fetchEmployeeData(employeeId)
      }
    },
    fetchEmployeeData (employeeId) {
      axios.get(`https://internal.cnexco.com:3000/employee/${employeeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Employee data fetched:', response.data)
          this.selectedEmployee.id = response.data.ID
          this.selectedEmployee.name = response.data.Username
          this.dates = this.generateDates()
          this.fetchAllData()
        })
        .catch(error => {
          console.error('Error fetching employee data:', error)
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken')
            localStorage.removeItem('employeeId')
            this.$router.push({ name: 'login' })
          }
        })
    },
    fetchAllData () {
      this.fetchPTOData()
      this.fetchPTOApprovalData()
      this.fetchSickLeaveApprovalData() // Sick Leaveのデータを取得
      this.fetchSickLeaveData() // Sick LeaveのTotalを取得
      this.fetchOvertimeOrders() // Overtime Orderのデータを取得
      this.fetchAttendanceData()
      this.fetchHolidays(this.currentYear)
    },
    fetchAttendanceData () {
      const month = `${this.currentYear}-${String(this.currentMonth).padStart(2, '0')}`
      axios.get(`https://internal.cnexco.com:3000/attendance/${this.selectedEmployee.id}/${month}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Attendance data fetched:', response.data)
          this.attendanceRecords = response.data
          this.updateDatesWithAttendance(response.data)
          this.updateTotalTimes()
        })
        .catch(error => {
          console.error('Error fetching attendance data:', error)
        })
    },
    fetchPTOData () {
      axios.get(`https://internal.cnexco.com:3000/pto/${this.selectedEmployee.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('PTO data fetched:', response.data)
          const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)
          const ptoData = response.data.find(data => data.Year === fiscalYearNumber)
          if (ptoData) {
            this.totalPTOHours = ptoData.PTO * 7.5
          } else {
            this.totalPTOHours = 0
          }
          this.calculateUsedPTO()
        })
        .catch(error => {
          console.error('Error fetching PTO data:', error)
        })
    },
    fetchPTOApprovalData () {
      const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)
      const startOfFiscalYear = `${fiscalYearNumber}-04-01`
      const endOfFiscalYear = `${fiscalYearNumber + 1}-03-31`

      axios.get(`https://internal.cnexco.com:3000/ptoapprove/${this.selectedEmployee.id}/year/${fiscalYearNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        },
        params: {
          startDate: startOfFiscalYear,
          endDate: endOfFiscalYear
        }
      })
        .then(response => {
          console.log('PTO approval data fetched:', response.data)
          this.usedPTOHours = response.data.reduce((total, pto) => {
            return total + this.timeStringToHours(pto.PTO)
          }, 0)
          this.remainingPTOHours = this.totalPTOHours - this.usedPTOHours

          this.updateDatesWithPTOApproval(response.data)
        })
        .catch(error => {
          console.error('Error fetching PTO approval data:', error)
        })
    },
    fetchSickLeaveApprovalData () {
      const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)

      axios.get(`https://internal.cnexco.com:3000/sickleaveapprove/${this.selectedEmployee.id}/year/${fiscalYearNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Sick Leave approval data fetched:', response.data)
          this.usedSickLeaveHours = response.data.reduce((total, leave) => {
            return total + this.timeStringToHours(leave.SickLeave)
          }, 0)
          this.remainingSickLeaveHours = this.totalSickLeaveHours - this.usedSickLeaveHours
          this.updateDatesWithSickLeaveApproval(response.data)
        })
        .catch(error => {
          console.error('Error fetching Sick Leave approval data:', error)
        })
    },
    fetchSickLeaveData () {
      axios.get(`https://internal.cnexco.com:3000/sickleave/${this.selectedEmployee.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Sick Leave data fetched:', response.data)
          const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)
          const sickLeaveData = response.data.find(data => data.Year === fiscalYearNumber)
          if (sickLeaveData) {
            this.totalSickLeaveHours = sickLeaveData.SickLeave * 7.5
          } else {
            this.totalSickLeaveHours = 0
          }
          this.remainingSickLeaveHours = this.totalSickLeaveHours - this.usedSickLeaveHours
        })
        .catch(error => {
          console.error('Error fetching Sick Leave data:', error)
        })
    },
    fetchOvertimeOrders () {
      const fiscalYearNumber = parseInt(this.fiscalYear.replace('FY', ''), 10)
      const startOfFiscalYear = `${fiscalYearNumber}-04-01`
      const endOfFiscalYear = `${fiscalYearNumber + 1}-03-31`

      axios.get(`https://internal.cnexco.com:3000/overtime/${this.selectedEmployee.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        },
        params: {
          startDate: startOfFiscalYear,
          endDate: endOfFiscalYear
        }
      })
        .then(response => {
          console.log('Overtime Order data fetched:', response.data)
          this.dates = this.dates.map(date => {
            const overtimeOrderData = response.data.find(data => data.Date.split('T')[0] === date.day)
            const otOrder = overtimeOrderData && overtimeOrderData.OverTimeOrder ? overtimeOrderData.OverTimeOrder.slice(0, 5) : '0:00'
            return {
              ...date,
              otOrder: otOrder // プロパティ名を統一
            }
          })
        })
        .catch(error => {
          console.error('Error fetching Overtime Order data:', error)
        })
    },
    updateDatesWithAttendance (attendanceData) {
      this.dates = this.dates.map(date => {
        const attendance = attendanceData.find(data => data.Date.split('T')[0] === date.day) || {}

        const updatedDate = {
          ...date,
          start: attendance.InTime ? attendance.InTime.slice(0, 5) : date.start,
          partialEnd: attendance.MidOutTime === '00:00:00' ? '' : attendance.MidOutTime ? attendance.MidOutTime.slice(0, 5) : date.partialEnd,
          partialStart: attendance.MidInTime === '00:00:00' ? '' : attendance.MidInTime ? attendance.MidInTime.slice(0, 5) : date.partialStart,
          end: attendance.OutTime ? attendance.OutTime.slice(0, 5) : date.end,
          break: attendance.BreakTime ? attendance.BreakTime.slice(0, 5) : this.calculateBreak(date.start, date.end, date.partialEnd, date.partialStart),
          paidLeave: attendance.PTO ? attendance.PTO.slice(0, 5) : date.paidLeave,
          sickLeave: date.sickLeave || '0:00',
          otOrder: date.otOrder || '0:00',
          hours: attendance.WorkHours ? (attendance.WorkHours === '00:00:00' ? '00:00' : attendance.WorkHours.slice(0, 5)) : date.hours,
          overtime: attendance.OvertimeHours ? attendance.OvertimeHours.slice(0, 5) : date.overtime,
          absence: attendance.AbsentHours ? (attendance.AbsentHours === '00:00:00' ? '00:00' : attendance.AbsentHours.slice(0, 5)) : date.absence,
          confirmed: attendance.SelfConf === 1,
          supervisorConfirmed: attendance.SupervisorConf === 1
        }

        updatedDate.hours = this.calculateWorkHours(updatedDate.start, updatedDate.partialEnd, updatedDate.partialStart, updatedDate.end, updatedDate.break, updatedDate.paidLeave, updatedDate.other)

        return updatedDate
      })

      this.updateTotalTimes()
    },
    updateDatesWithSickLeaveApproval (sickLeaveApprovalData) {
      this.dates = this.dates.map(date => {
        const sickLeaveApproval = sickLeaveApprovalData.find(data => {
          const sickLeaveDate = data.Date.split('T')[0]
          return sickLeaveDate === date.day
        }) || {}
        const sickLeave = sickLeaveApproval.SickLeave ? sickLeaveApproval.SickLeave.slice(0, 5) : '0:00'
        return {
          ...date,
          sickLeave: sickLeave
        }
      })
    },
    updateDatesWithPTOApproval (ptoApprovalData) {
      this.dates = this.dates.map(date => {
        const ptoApproval = ptoApprovalData.find(data => {
          const ptoDate = data.Date.split('T')[0]
          return ptoDate === date.day
        }) || {}
        const paidLeave = ptoApproval.PTO ? ptoApproval.PTO.slice(0, 5) : '0:00'
        return {
          ...date,
          paidLeave: paidLeave
        }
      })
    },
    calculateUsedPTO () {
      this.fetchPTOApprovalData()
    },
    fetchHolidays (year) {
      axios.get(`https://internal.cnexco.com:3000/holidays/${year}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          this.holidays = response.data
        })
        .catch(error => {
          console.error('Error fetching holidays:', error)
        })
    },
    generateDates () {
      const dates = []
      const startDay = this.currentDate < 15 ? 1 : 16
      const endDay = this.currentDate < 15 ? 15 : moment.tz(`${this.currentYear}-${String(this.currentMonth).padStart(2, '0')}`, 'America/Chicago').daysInMonth()
      const now = moment.tz('America/Chicago')
      for (let day = startDay; day <= endDay; day++) {
        const dateStr = `${this.currentYear}-${String(this.currentMonth).padStart(2, '0')}-${String(day).padStart(2, '0')}`
        const future = moment.tz(dateStr, 'America/Chicago').isAfter(now)
        dates.push({
          day: dateStr,
          start: future ? '' : this.inTime || '09:00',
          end: future ? '' : this.outTime || '17:30',
          partialEnd: '',
          partialStart: '',
          paidLeave: '',
          sickLeave: '0:00',
          otOrder: '0:00',
          hours: '0:00',
          overtime: '0:00',
          absence: '0:00',
          future: future,
          confirmed: false,
          supervisorConfirmed: false
        })
      }
      return dates
    },
    moveForward () {
      this.adjustDate(true)
      this.dates = this.generateDates()
      this.fetchAllData()
    },
    moveBackward () {
      this.adjustDate(false)
      this.dates = this.generateDates()
      this.fetchAllData()
    },
    adjustDate (forward) {
      const currentDate = new Date(Date.UTC(this.currentYear, this.currentMonth - 1, this.currentDate))
      if (forward) {
        if (this.currentDate < 15) {
          this.currentDate = 16
        } else {
          currentDate.setUTCMonth(currentDate.getUTCMonth() + 1)
          this.currentYear = currentDate.getUTCFullYear()
          this.currentMonth = currentDate.getUTCMonth() + 1
          this.currentDate = 1
        }
      } else {
        if (this.currentDate > 15) {
          this.currentDate = 1
        } else {
          currentDate.setUTCMonth(currentDate.getUTCMonth() - 1)
          this.currentYear = currentDate.getUTCFullYear()
          this.currentMonth = currentDate.getUTCMonth() + 1
          this.currentDate = 16
        }
      }
    },
    handleTimeChange (date, field) {
      if (date[field]) {
        if (field === 'end' || field === 'partialEnd') {
          date[field] = this.roundTimeDown(date[field])
        } else {
          date[field] = this.roundTimeUp(date[field])
        }

        if (this.checkTimeSequence(date)) {
          date.break = this.calculateBreak(date.start, date.end, date.partialEnd, date.partialStart)
          date.hours = this.calculateWorkHours(date.start, date.partialEnd, date.partialStart, date.end, date.break, date.paidLeave, date.sickLeave)
          date.overtime = this.calculateOvertime(date.start, date.partialEnd, date.partialStart, date.end, date.break)
          date.absence = this.calculateAbsence(date.hours, date.paidLeave, date.sickLeave)
        } else {
          alert('Time series Error! Please re-enter the time.')
          date.partialEnd = ''
          date.partialStart = ''
          date.start = '09:00'
          date.end = '17:30'
          date.break = this.calculateBreak(date.start, date.end)
          date.hours = this.calculateWorkHours(date.start, '', '', date.end, date.break, '0:00', '0:00')
          date.overtime = this.calculateOvertime(date.start, '', '', date.end, date.break)
          date.absence = this.calculateAbsence(date.hours, date.paidLeave, date.sickLeave)
        }
      } else {
        date.partialEnd = ''
        date.partialStart = ''
        date.end = ''
        date.break = '0:00'
        date.hours = this.calculateWorkHoursForBlankStart(date.paidLeave, date.sickLeave)
        date.overtime = '00:00'
        date.absence = this.calculateAbsence(date.hours, date.paidLeave, date.sickLeave)
      }

      if (date.partialEnd === '00:00') {
        date.partialEnd = ''
      }
      if (date.partialStart === '00:00') {
        date.partialStart = ''
      }

      this.updateTotalTimes()
    },
    handleLeaveChange (date, field) {
      if (!date.start) {
        date.hours = this.calculateWorkHoursForBlankStart(date.paidLeave, date.sickLeave)
      } else {
        date.hours = this.calculateWorkHours(date.start, date.partialEnd, date.partialStart, date.end, date.break, date.paidLeave, date.sickLeave)
      }

      this.updateTotalTimes()
    },
    toggleConfirmation (date) {
      const ptoHours = this.timeStringToHours(date.paidLeave)
      const otherHours = this.timeStringToHours(date.sickLeave)
      const totalHours = ptoHours + otherHours + this.timeStringToHours(date.hours)

      if ((ptoHours !== 0 || otherHours !== 0) && totalHours > 7.5) {
        alert('Error: The total of PTO, Sick Leave, and Work Hours exceeds 7:30 hours. Please adjust the values.')
        return
      }

      date.confirmed = !date.confirmed
      if (date.confirmed) {
        this.updateAttendance(date)
      } else {
        this.deleteAttendance(date)
      }
    },
    updateAttendance (date) {
      const attendanceData = {
        EmployeeID: this.selectedEmployee.id,
        Date: date.day,
        InTime: date.start || '00:00:00',
        MidOutTime: date.partialEnd || '00:00:00',
        MidInTime: date.partialStart || '00:00:00',
        OutTime: date.end || '00:00:00',
        BreakTime: date.break || '00:00:00',
        PTO: date.paidLeave || '00:00:00',
        Others: date.sickLeave || '00:00:00',
        WorkHours: date.hours || '00:00:00',
        OvertimeHours: date.overtime || '00:00:00',
        OTOrder: date.otOrder || '00:00:00',
        AbsentHours: date.absence || '00:00:00',
        SelfConf: date.confirmed ? 1 : 0
      }
      axios.post('https://internal.cnexco.com:3000/attendance', attendanceData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Attendance updated:', response.data)
        })
        .catch(error => {
          console.error('Error updating attendance:', error)
        })
    },
    deleteAttendance (date) {
      const attendanceData = {
        EmployeeID: this.selectedEmployee.id,
        Date: date.day
      }
      axios.delete('https://internal.cnexco.com:3000/attendance', {
        data: attendanceData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      })
        .then(response => {
          console.log('Attendance deleted:', response.data)
        })
        .catch(error => {
          console.error('Error deleting attendance:', error)
        })
    },
    generateTimeOptions (maxTime) {
      const maxHours = this.timeStringToHours(maxTime)
      const options = []
      for (let i = 0; i <= maxHours; i += 0.5) {
        options.push(this.hoursToTimeString(i))
      }
      return options
    },
    timeStringToHours (timeStr) {
      if (!timeStr) return 0
      const parts = timeStr.split(':')
      return parseFloat(parts[0]) + parseFloat(parts[1]) / 60
    },
    hoursToTimeString (hours) {
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')}`
    },
    hoursToDaysAndTimeString (hours) {
      const days = (hours / 7.5).toFixed(2)
      const h = Math.floor(hours)
      const m = Math.round((hours - h) * 60)
      return `${days} Days (${h.toString().padStart(2, '0')}:${(m % 60).toString().padStart(2, '0')})`
    },
    sumTimes (times) {
      const totalMinutes = times.reduce((acc, time) => {
        if (!time || time === '0:00') return acc
        const [hours, minutes] = time.split(':').map(Number)
        return acc + hours * 60 + minutes
      }, 0)

      const h = Math.floor(totalMinutes / 60)
      const m = totalMinutes % 60
      return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
    },
    roundTimeUp (timeStr) {
      const [hours, minutes] = timeStr.split(':').map(Number)
      const roundedMinutes = minutes > 0 ? (minutes <= 30 ? 30 : 60) : 0
      const roundedHours = roundedMinutes === 60 ? hours + 1 : hours
      return `${roundedHours.toString().padStart(2, '0')}:${(roundedMinutes % 60).toString().padStart(2, '0')}`
    },
    roundTimeDown (timeStr) {
      const [hours, minutes] = timeStr.split(':').map(Number)
      const roundedMinutes = minutes > 30 ? 30 : 0
      return `${hours.toString().padStart(2, '0')}:${roundedMinutes.toString().padStart(2, '0')}`
    },
    checkTimeSequence (date) {
      const startTime = new Date(`1970-01-01T${date.start}Z`)
      const partialEndTime = date.partialEnd ? new Date(`1970-01-01T${date.partialEnd}Z`) : null
      const partialStartTime = date.partialStart ? new Date(`1970-01-01T${date.partialStart}Z`) : null
      const endTime = new Date(`1970-01-01T${date.end}Z`)

      if (partialEndTime && partialStartTime) {
        return startTime < partialEndTime && partialEndTime < partialStartTime && partialStartTime < endTime
      } else {
        return startTime < endTime
      }
    },
    calculateBreak (start, end, partialEnd = null, partialStart = null) {
      const startTime = new Date(`1970-01-01T${start}Z`)
      const endTime = new Date(`1970-01-01T${end}Z`)
      const partialEndTime = partialEnd ? new Date(`1970-01-01T${partialEnd}Z`) : null
      const partialStartTime = partialStart ? new Date(`1970-01-01T${partialStart}Z`) : null

      if (!partialEnd && !partialStart) {
        const duration = (endTime - startTime) / (1000 * 60 * 60)
        if (duration >= 6) {
          return '1:00'
        } else {
          return '0:00'
        }
      } else if (partialEnd === '00:00' && partialStart === '00:00') {
        const duration = (endTime - startTime) / (1000 * 60 * 60)
        if (duration >= 6) {
          return '1:00'
        } else {
          return '0:00'
        }
      } else {
        if ((partialStartTime - partialEndTime) / (1000 * 60 * 60) >= 6 || (endTime - partialStartTime) / (1000 * 60 * 60) >= 6) {
          return '1:00'
        }
      }
      return '0:00'
    },
    calculateWorkHours (start, partialEnd, partialStart, end, breakTime, paidLeave, other) {
      if ((start === '00:00' && end === '00:00') || (!start || !end)) {
        return '00:00'
      }

      const startTime = new Date(`1970-01-01T${start}Z`)
      const partialEndTime = partialEnd ? new Date(`1970-01-01T${partialEnd}Z`) : null
      const partialStartTime = partialStart ? new Date(`1970-01-01T${partialStart}Z`) : null
      const endTime = new Date(`1970-01-01T${end}Z`)

      let duration = 0
      if (partialEndTime && partialStartTime) {
        duration = (partialEndTime - startTime + (endTime - partialStartTime)) / (1000 * 60 * 60)
      } else {
        duration = (endTime - startTime) / (1000 * 60 * 60)
      }

      const breakHours = this.timeStringToHours(breakTime)
      const workHours = duration - breakHours
      return this.hoursToTimeString(workHours)
    },
    calculateWorkHoursForBlankStart (paidLeave, other) {
      const workHours = 0
      return this.hoursToTimeString(workHours)
    },
    calculateOvertime (start, partialEnd, partialStart, end, breakTime) {
      const startTime = new Date(`1970-01-01T${start}Z`)
      const partialEndTime = partialEnd ? new Date(`1970-01-01T${partialEnd}Z`) : null
      const partialStartTime = partialStart ? new Date(`1970-01-01T${partialStart}Z`) : null
      const endTime = new Date(`1970-01-01T${end}Z`)

      let workDuration = 0
      if (partialEndTime && partialStartTime) {
        workDuration = (partialEndTime - startTime + (endTime - partialStartTime)) / (1000 * 60 * 60)
      } else {
        workDuration = (endTime - startTime) / (1000 * 60 * 60)
      }

      const breakHours = this.timeStringToHours(breakTime)
      const overtime = Math.max(workDuration - breakHours - 7.5, 0)
      return this.hoursToTimeString(overtime)
    },
    calculateAbsence (workHours, paidLeave, other) {
      const requiredHours = 7.5 - this.timeStringToHours(paidLeave) - this.timeStringToHours(other)
      const workHoursFloat = this.timeStringToHours(workHours)
      const absence = requiredHours - workHoursFloat
      return this.hoursToTimeString(Math.max(absence, 0))
    },
    updateTotalTimes () {
      this.$forceUpdate()
    }
  },
  created () {
    this.timeOptions = this.generateTimeOptions('7:30')
    this.checkAuth()
  }
}
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px;
}

.pto-and-sickleave-and-date-switcher {
  display: flex;
  align-items: flex-end; /* Align to the bottom */
  margin-bottom: 20px;
}

.date-switcher, .employee-info, .date-table {
  margin-bottom: 20px;
}

.date-switcher {
  display: flex;
  align-items: center;
  margin-left: 20px; /* Space between the table and the switcher */
}

.date-switcher button {
  background: none;
  border: none;
}

.date-switcher span {
  font-size: 1.2em; /* Increase font size */
  font-weight: bold; /* Bold text */
  margin: 0 10px; /* Space between the buttons and the text */
}

.pto-table, .sickleave-table {
  margin-top: 10px;
}

.date-table {
  width: 100%;
  overflow-x: auto; /* Add horizontal scroll */
}

.date-table table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  padding: 6px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  font-size: 0.9em; /* Slightly reduce font size */
}

th {
  background-color: #f2f2f2;
  text-overflow: ellipsis;
}

.confirmed {
  background-color: #e0e0e0; /* Light gray background for confirmed rows */
}

.supervisor-confirmed-text {
  color: green; /* Green color */
  font-weight: bold; /* Bold text */
}

.full-width {
  width: 100%;
}

.highlighted {
  color: red;
}
</style>

<template>
    <div class="register-container">
      <h2>Update User Information</h2>
      <form @submit.prevent="registerOrUpdateUser">
        <div class="form-group">
          <label for="employeeSelect">Select Employee:</label>
          <select v-model="selectedEmployee" @change="fetchEmployeeData">
            <option disabled value="">Please select one</option>
            <option v-for="employee in employees" :key="employee.Id" :value="employee.Id">{{ employee.Username }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="id">ID:</label>
          <input type="number" v-model.number="form.id" @input="validateId" :disabled="!selectedFields.includes('id')" required />
          <input type="checkbox" v-model="selectedFields" value="id" />
        </div>
        <div class="form-group">
          <label for="username">Username:</label>
          <input type="text" v-model="form.username" :disabled="!selectedFields.includes('username')" required />
          <input type="checkbox" v-model="selectedFields" value="username" />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input :type="showPassword ? 'text' : 'password'" v-model="form.password" :disabled="!selectedFields.includes('password')" />
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password:</label>
          <input :type="showPassword ? 'text' : 'password'" v-model="form.confirmPassword" :disabled="!selectedFields.includes('password')" />
          <input type="checkbox" v-model="selectedFields" value="password" />
        </div>
        <div class="form-group show-password">
          <input type="checkbox" v-model="showPassword" id="showPassword" />
          <label for="showPassword">Show Password</label>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" v-model="form.email" :disabled="!selectedFields.includes('email')" required />
          <input type="checkbox" v-model="selectedFields" value="email" />
        </div>
        <div class="form-group">
          <label for="joinDate">Join Date:</label>
          <input type="date" v-model="form.joinDate" :disabled="!selectedFields.includes('joinDate')" required />
          <input type="checkbox" v-model="selectedFields" value="joinDate" />
        </div>
        <div class="form-group">
          <label for="department">Department:</label>
          <input type="text" v-model="form.department" :disabled="!selectedFields.includes('department')" required />
          <input type="checkbox" v-model="selectedFields" value="department" />
        </div>
        <div class="form-group">
          <label for="intime">In Time:</label>
          <input type="time" v-model="form.intime" :disabled="!selectedFields.includes('intime')" required />
          <input type="checkbox" v-model="selectedFields" value="intime" />
        </div>
        <div class="form-group">
          <label for="outtime">Out Time:</label>
          <input type="time" v-model="form.outtime" :disabled="!selectedFields.includes('outtime')" required />
          <input type="checkbox" v-model="selectedFields" value="outtime" />
        </div>
        <div class="form-group">
          <label for="systemAccessLevel">System Access Level:</label>
          <select v-model="form.systemAccessLevel" :disabled="!selectedFields.includes('systemAccessLevel')" required>
            <option value="Staff">Staff</option>
            <option value="Management">Management</option>
            <option value="Administrator">Administrator</option>
          </select>
          <input type="checkbox" v-model="selectedFields" value="systemAccessLevel" />
        </div>
        <div class="form-group">
          <label for="supervisorName">Supervisor Name:</label>
          <input type="text" v-model="form.supervisorName" :required="form.hasSupervisor" :disabled="!form.hasSupervisor || !selectedFields.includes('supervisorName')" />
          <input type="checkbox" v-model="selectedFields" value="supervisorName" />
          <input type="checkbox" v-model="form.hasSupervisor" id="hasSupervisor" />
        </div>
        <button type="submit">{{ selectedEmployee ? 'Update' : 'Update' }}</button>
      </form>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      employees: [],
      selectedEmployee: '',
      selectedFields: [],
      form: {
        id: null,
        username: '',
        password: '',
        confirmPassword: '',
        email: '',
        joinDate: '',
        department: '',
        systemAccessLevel: 'Staff',
        intime: '',
        outtime: '',
        hasSupervisor: false,
        supervisorName: ''
      },
      showPassword: false
    }
  },
  methods: {
    async fetchEmployees () {
      try {
        const token = localStorage.getItem('authToken')
        const response = await axios.get('https://internal.cnexco.com:3000/employees', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        this.employees = response.data
      } catch (error) {
        this.showMessage('Error fetching employees: ' + error.message)
      }
    },
    async fetchEmployeeData () {
      if (!this.selectedEmployee) return

      try {
        const token = localStorage.getItem('authToken')
        const response = await axios.get(`https://internal.cnexco.com:3000/update-employee-data/${this.selectedEmployee}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        const employee = response.data

        this.form.id = employee.Id
        this.form.username = employee.Username
        this.form.email = employee.Email
        this.form.joinDate = employee.JoinDate ? new Date(employee.JoinDate).toISOString().substr(0, 10) : ''
        this.form.department = employee.Department
        this.form.systemAccessLevel = employee.SystemAccessLevel
        this.form.intime = employee.Intime
        this.form.outtime = employee.Outtime
        this.form.supervisorName = employee.SupervisorName || ''
        this.form.hasSupervisor = !!employee.SupervisorName
      } catch (error) {
        this.showMessage('Error fetching employee data: ' + error.message)
      }
    },
    async registerOrUpdateUser () {
      if (this.form.password && this.form.password !== this.form.confirmPassword) {
        this.showMessage('Passwords do not match!')
        return
      }

      if (!this.validateTimeDifference()) {
        this.showMessage('The difference between the Out Time and the In Time must be 8:30: regular work hours for 7:30 and 1 hour break')
        this.form.intime = ''
        this.form.outtime = ''
        return
      }

      try {
        const token = localStorage.getItem('authToken')
        if (!this.form.hasSupervisor) {
          this.form.supervisorName = null
        }

        const updatedFields = {}
        this.selectedFields.forEach(field => {
          updatedFields[field] = this.form[field]
        })

        if (this.selectedEmployee) {
          // 更新
          await axios.put(`https://internal.cnexco.com:3000/update-employee/${this.selectedEmployee}`, updatedFields, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          this.showMessage('User updated successfully!')
        } else {
          // 登録
          await axios.post('https://internal.cnexco.com:3000/register', this.form, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          this.showMessage('User registered successfully!')
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.showMessage('Error: ' + error.response.data)
        } else {
          this.showMessage('Error: ' + error.message)
        }
      }
    },
    validateId (event) {
      const value = event.target.value
      if (value < 1) {
        this.form.id = ''
      }
    },
    validateTimeDifference () {
      const intime = this.form.intime
      const outtime = this.form.outtime

      if (!intime || !outtime) {
        return false
      }

      const [inHours, inMinutes] = intime.split(':').map(Number)
      const [outHours, outMinutes] = outtime.split(':').map(Number)

      const inTimeInMinutes = inHours * 60 + inMinutes
      const outTimeInMinutes = outHours * 60 + outMinutes

      const differenceInMinutes = outTimeInMinutes - inTimeInMinutes

      return differenceInMinutes === 510 // 8 hours 30 minutes = 510 minutes
    },
    showMessage (message) {
      alert(message)
    }
  },
  mounted () {
    this.fetchEmployees()
  }
}
</script>

  <style scoped>
  .register-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    text-align: center;
  }

  form {
    width: 100%;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .form-group label {
    width: 150px;
    margin-right: 10px;
    text-align: right;
  }

  .form-group input[type="text"],
  .form-group input[type="number"],
  .form-group input[type="password"],
  .form-group input[type="email"],
  .form-group input[type="date"],
  .form-group input[type="time"],
  .form-group select {
    flex: 1;
    padding: 8px;
  }

  .form-group input[type="checkbox"] {
    margin-left: 10px;
  }

  .form-group.show-password {
    justify-content: flex-start;
  }

  .form-group.show-password input[type="checkbox"] {
    margin-left: 160px;
  }

  .form-group.show-password label {
    margin-left: 5px;
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    border: none;
    color: white;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
  </style>

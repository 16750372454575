<template>
    <div class="dashboard">
      <header>
        <div class="header-content">
          <div class="logo">
            <img :src="logoSrc" alt="ロゴ">
          </div>
          <div class="site-name">NEXCO Highway Solutions of America Inc. <br> - Internal Management System</div>
          <div class="user-info">
            <img :src="logoutSrc" alt="ログアウト" @click="logout">
          </div>
        </div>
      </header>
      <div class="main-container">
        <aside>
          <ul>
            <template v-if="accessLevel >= accessLevels.Staff">
              <li class="category">[Attendance]</li>
              <li><router-link to="/dashboard/attendance/timesheet">Time Sheet</router-link></li>
              <li><router-link to="/dashboard/attendance/request-pto">Request PTO</router-link></li>
              <li><router-link to="/dashboard/attendance/request-sickleave">Request Sick Leave</router-link></li>
            </template>

            <template v-if="accessLevel >= accessLevels.Management">
              <li class="category">[Management]</li>
              <li><router-link to="/dashboard/attendance/timecheck">Attendance Check</router-link></li>
              <li><router-link to="/dashboard/attendance/overtime-order">Order Overtime</router-link></li>
              <li><router-link to="/dashboard/attendance/check-pto">Approve PTO</router-link></li>
              <li><router-link to="/dashboard/attendance/check-sickleave">Approve Sick Leave</router-link></li>
            </template>
            <template v-if="accessLevel >= accessLevels.Administrator">
              <li><router-link to="/dashboard/attendance/administration">Administration</router-link></li>
            </template>

            <template v-if="accessLevel >= accessLevels.Administrator">
              <li class="category">[Setting]</li>
              <li><router-link to="/dashboard/hrm/add-user">Add User</router-link></li>
              <li><router-link to="/dashboard/hrm/update-user">Update User</router-link></li>
              <li><router-link to="/dashboard/hrm/hrmsetting">HR Setting</router-link></li>
              <li><router-link to="/dashboard/system/update-holiday">Holiday Update</router-link></li>
            </template>
          </ul>
        </aside>
        <main>
          <router-view></router-view>
        </main>
      </div>
    </div>
  </template>

<script>
export default {
  data () {
    return {
      accessLevels: {
        Administrator: 3,
        Management: 2,
        Staff: 1
      },
      accessLevel: 0, // 初期値を設定
      logoSrc: require('@/assets/NEXCO.jpg'), // 画像のパスを指定
      logoutSrc: require('@/assets/logout.png') // ログアウト画像のパスを指定
    }
  },
  created () {
    // localStorageからアクセスレベルを取得して数値に変換
    const storedAccessLevel = localStorage.getItem('systemAccessLevel')
    if (storedAccessLevel) {
      this.accessLevel = this.accessLevels[storedAccessLevel]
    }
    this.redirectToDefault()
  },
  methods: {
    logout () {
      // Handle logout logic here
      localStorage.removeItem('authToken')
      localStorage.removeItem('employeeId')
      localStorage.removeItem('systemAccessLevel')
      this.$router.push('/')
    },
    redirectToDefault () {
      // 初期化時にTimeSheetへリダイレクト
      if (this.accessLevel >= this.accessLevels.Staff) {
        this.$router.replace('/dashboard/attendance/timesheet')
      }
    }
  }
}
</script>

  <style>
    body {
      margin: 0;
      font-family: 'Roboto', sans-serif;
      background-color: #f0f0f0;
      font-size: 1vw; /* ベースフォントサイズを相対値で設定 */
    }

    .dashboard {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }

    header {
      background-color: #4A4A4A; /* ダークブルー */
      padding: 1vw 1.5vw; /* パディングを相対値で設定 */
      color: white;
      box-shadow: 0 0.2vw 0.4vw rgba(0, 0, 0, 0.1); /* シャドウを相対値で設定 */
    }

    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo img {
      width: 8vw; /* ロゴ画像の幅を相対値で設定 */
      max-width: 15vw; /* ロゴ画像の最大幅を相対値で設定 */
      height: auto; /* 高さを自動調整 */
    }

    .site-name {
      margin-left: 1.5vw; /* マージンを相対値で設定 */
      font-size: 1.8em;
      text-align: left;
      flex-grow: 1;
      font-weight: bold;
    }

    .user-info img {
      width: 7vw; /* ログアウト画像の幅を相対値で設定 */
      height: auto; /* ログアウト画像の高さを相対値で設定 */
      cursor: pointer;
    }

    .main-container {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
    }

    aside {
      background-color: #6d6d6d; /* グレイ */
      color: white;
      padding: 0.5vw 2vw; /* パディングを相対値で設定 */
      width: 10vw; /* 幅を相対値で設定 */
      display: flex;
      flex-direction: column;
      align-items: start;
      box-shadow: 0.2vw 0 0.5vw rgba(0, 0, 0, 0.1); /* シャドウを相対値で設定 */
      overflow-y: auto; /* スクロールバーを追加 */
    }

    aside ul {
      list-style-type: none;
      padding: 0;
      width: 100%;
    }

    .category {
      font-size: 1.1em; /* カテゴリ名のフォントサイズを設定 */
      font-weight: bold;
      margin: 2vw 0 1vw; /* カテゴリ名のマージンを設定 */
      color: #FFFFFF; /* カテゴリ名の色を設定 */
    }

    aside li {
      margin: 0.2vw 0; /* リンク間のマージンをさらに狭く設定 */
      width: 100%;
    }

    aside a {
      text-decoration: none;
      color: white;
      display: block;
      width: 100%;
      padding: 0.5vw 0.5vw; /* パディングを相対値で設定 */
      border-radius: 0.5vw; /* ボーダー半径を相対値で設定 */
      transition: background 0.3s, color 0.3s;
      font-weight: normal;
    }

    aside a:hover {
      background-color: #1E90FF; /* ホバー効果の色をライトブルーに変更 */
      color: white;
    }

    main {
      flex-grow: 1;
      padding: 3vw; /* パディングを相対値で設定 */
      background-color: #ffffff;
      overflow-y: auto;
      box-shadow: inset 0 0 1vw rgba(0, 0, 0, 0.1); /* シャドウを相対値で設定 */
    }
  </style>
